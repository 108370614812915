export function callbackHost(): string {
  return window.location.protocol + '//' + window.location.host
}

export function authorityHost(): string {
  return process.env.NODE_ENV === 'production'
    ? window.location.protocol + '//' + window.location.host
    : process.env.VUE_APP_AUTHORITY_HOST
}

export function apiHost(): string {
  return ['production', 'preview', 'staging'].includes(process.env.NODE_ENV)
    ? window.location.protocol + '//' + window.location.host
    : process.env.VUE_APP_API_HOST
}

export function vncHost(): string {
  return process.env.NODE_ENV === 'production' ? 'horstcosmos.com' : process.env.VUE_APP_VNC_HOST
}
