import { shipSystem } from '@/data/systems'
import { RobotSystem } from '@/interfaces'
import { RobotIssue } from 'hcosmos-api-data'
import { resetPassword } from './resetPassword'
import { useToast } from 'vue-toastification'

const toast = useToast()

export const createShipSystem = function () {
  return async (system: RobotSystem, panelSerial: string, controlSerial: string, issue?: RobotIssue) => {
    try {
      await shipSystem(
        system,
        issue?.erpOrderId ? issue.erpOrderId : '',
        issue?.erpCustomerId ? issue.erpCustomerId : '',
        issue?.jiraIssue ? issue.jiraIssue : '',
      )
      toast.success('Daten erfolgreich gesendet')
      return resetPassword(system.id)
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error('Error while creating shipment')
      }
      throw 'Error while creating shipment'
    }
  }
}
