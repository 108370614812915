import { licenseApi } from '@/data'

function createRequestSignedLicenses() {
  return async (start: Date, end: Date, clientData: string) => {
    return licenseApi.getSignedLicenses({
      start,
      end,
      clientId: clientData,
    })
  }
}

export { createRequestSignedLicenses }
