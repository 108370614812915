import { uiOptions } from '@/config'
import { apiBusyState } from '@/data'
import { ILicensePackageInformationWithStatus } from '@/interfaces'
import { convertLicensePackageInformationWithStatus } from '@/stores/packageInformationLicense/fetchPackageInformationLicenses'
import { faker } from '@faker-js/faker'
import {
  LicenseRentalInformation,
  LicensePackageInformation,
  LicensePackageInformationTypeEnum,
  LicenseFeatureInformation,
} from 'hcosmos-api-data'

/**
 * Function that auto generates or gets DUMMY data to
 * populate the table, first creates data with an array of LicensePackageInformation
 * then populates to an array of LicensePackageInformationWithStatus
 * @returns
 */
export default async function licensePackageInformationWithStatusDummyValues(): Promise<
  ILicensePackageInformationWithStatus[]
> {
  apiBusyState.packageInformationLicenses = true
  await new Promise((resolve) => setTimeout(resolve, uiOptions().dummyDelayTime))
  const dummyLicensesPackagesWithStatus: ILicensePackageInformationWithStatus[] = []
  const dummyLicensesPackages: LicensePackageInformation[] = []

  while (dummyLicensesPackages.length < 15) {
    dummyLicensesPackages.push(createLicensePackageDummy())
  }

  dummyLicensesPackages.forEach((dummyLicensePackage) => {
    dummyLicensesPackagesWithStatus.push(convertLicensePackageInformationWithStatus(dummyLicensePackage))
  })

  apiBusyState.packageInformationLicenses = false
  return dummyLicensesPackagesWithStatus
}

function createLicensePackageDummy(): LicensePackageInformation {
  const { Group, User } = LicensePackageInformationTypeEnum
  const dummyFeatures: LicenseFeatureInformation[] = []
  const dummyRentalInformation: LicenseRentalInformation[] = []

  while (dummyFeatures.length < 5) {
    dummyFeatures.push(createFeatureDummy())
  }

  while (dummyRentalInformation.length < 5) {
    dummyRentalInformation.push(createRentalInformationDummy())
  }

  const dummyFeaturesSet = new Set(dummyFeatures)
  const dummyRentalInformationSet = new Set(dummyRentalInformation)

  return {
    jiraIssue: faker.lorem.word().toLocaleUpperCase(),
    type: Math.random() > 0.1 ? Group : User,
    title: {
      de_DE: faker.lorem.words(4),
      en_US: faker.lorem.words(4),
    },
    timeStart: faker.date.past(),
    timeEnd: Math.random() > 0.8 ? faker.date.recent(19) : faker.date.future(),
    features: Math.random() > 0.3 ? dummyFeaturesSet : undefined,
    owner: faker.lorem.word(6),
    comment: Math.random() > 0.4 ? faker.lorem.sentences(2) : undefined,
    rented: Math.random() > 0.3 ? dummyRentalInformationSet : undefined,
  } as LicensePackageInformation
}

function createRentalInformationDummy() {
  return {
    user: {
      id: faker.datatype.uuid(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
    },
    clientId: faker.datatype.uuid(),
    timeStart: Math.random() > 0.4 ? faker.date.recent(30) : faker.date.recent(10),
    timeEnd: Math.random() > 0.6 ? faker.date.soon(20) : faker.date.recent(5),
  } as LicenseRentalInformation
}

function createFeatureDummy() {
  return {
    id: faker.datatype.uuid(),
    title: {
      de_DE: faker.lorem.words(3),
      en_US: faker.lorem.words(3),
    },
  } as LicenseFeatureInformation
}
