import { JiraIssueErrorDescriptionCheckbox } from 'hcosmos-api-service'

export const errorCheckboxesValues: Array<JiraIssueErrorDescriptionCheckbox> = [
  {
    self: 'https://atlassian-test.fruitcore.de/jira/rest/api/2/customFieldOption/11012',
    value: 'Aktive Internetverbindung zu hCONTROL für Remote-Zugriff möglich?',
    id: '11012',
    disabled: false,
  },
  {
    self: 'https://atlassian-test.fruitcore.de/jira/rest/api/2/customFieldOption/11013',
    value: 'Wurde ein Video zum Fehler angehängt?',
    id: '11013',
    disabled: false,
  },
  {
    self: 'https://atlassian-test.fruitcore.de/jira/rest/api/2/customFieldOption/11014',
    value: 'Wurde das Roboter-Programm angehängt?',
    id: '11014',
    disabled: false,
  },
  {
    self: 'https://atlassian-test.fruitcore.de/jira/rest/api/2/customFieldOption/11015',
    value: 'Wurde der Fehlerstatus (horstFX.zip) angehängt?',
    id: '11015',
    disabled: false,
  },
]
