import { IUIOptions } from '@/interfaces'

export const modalsIdList = {
  formRequestLicense: false,
  bookedLicenses: false,
  upgradeHorst365: false,
  upgradeRobotSoftware: false,
  confirmationMessage: false,
  buyDesktopLicense: false,
  formDownloadLicenses: false,
  formCreateServiceTickets: false,
  issueComments: false,
} as IUIOptions['isModalOpen']

export type ModalIds = keyof typeof modalsIdList
