import { RobotSystem } from '@/interfaces'
import { systemApi } from '..'

/**
 *
 * @param system
 * @param status
 */
export async function updateSystemStatus(systemId: string, status: 'PRODUCTION' | 'ACTIVE' | 'PACKAGED') {
  try {
    await systemApi.updateStatues({
      body: status,
      id: systemId,
    })
  } catch (e) {
    throw new Error(
      `Robotersystem konnte nicht in den Zustand "${status}" versetzt werden: ${(e as Response).status}${
        (e as Response).statusText
      }`,
    )
  }
}
