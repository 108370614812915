import { AggregationUnit } from '@/enums'
import { lookup } from '@/utils/lookup'
import { GetUserDataAggregationUnitEnum } from 'hcosmos-api-data'

export const aggregationUnitLookup = lookup(
  {
    [AggregationUnit.DAILY]: GetUserDataAggregationUnitEnum.Day,
    [AggregationUnit.HOURLY]: GetUserDataAggregationUnitEnum.Hour,
    [AggregationUnit.MINUTELY]: GetUserDataAggregationUnitEnum.Minute,
    [AggregationUnit.SECONDLY]: GetUserDataAggregationUnitEnum.Second, // TODO
    [AggregationUnit.RAW]: GetUserDataAggregationUnitEnum.None,
  },
  GetUserDataAggregationUnitEnum.None,
)
