import { AggregationMethod } from '@/enums'
import { lookup } from '@/utils/lookup'
import { GetUserDataAggregationMethodEnum } from 'hcosmos-api-data'

export const aggregationMethodLookup = lookup(
  {
    [AggregationMethod.MIN]: GetUserDataAggregationMethodEnum.Min,
    [AggregationMethod.MAX]: GetUserDataAggregationMethodEnum.Max,
    [AggregationMethod.MEAN]: GetUserDataAggregationMethodEnum.Mean,
    [AggregationMethod.TOTAL]: GetUserDataAggregationMethodEnum.Sum,
    [AggregationMethod.RAW]: GetUserDataAggregationMethodEnum.None,
  },
  GetUserDataAggregationMethodEnum.None,
)
