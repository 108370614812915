import { apiBusyState, systemApi } from '@/data'
import { createRobotSystemFromApiModel } from '@/data/systems'
import { RobotSystem } from '@/interfaces'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'

const toast = useToast()
const { t } = i18n.global

function createFetchSystems(ref: Ref<RobotSystem[]>) {
  return async (revalidate: boolean = false) => {
    if (!revalidate && ref.value.length > 0) {
      return ref.value
    }
    try {
      apiBusyState.system = true
      const robots = await systemApi.listDetailed()
      const mapped = robots.map(createRobotSystemFromApiModel)

      ref.value = mapped
      return mapped
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
      return ref.value
    } finally {
      apiBusyState.system = false
    }
  }
}

export { createFetchSystems }
