import { AddAttachmentsRequest } from 'hcosmos-api-service'
import { VueUploadItem } from 'vue-upload-component'
import { attachmentApi } from '@/data'
import { useToast } from 'vue-toastification'
import { apiBusyState } from '@/data/states'
import i18n from '@/locales/i18n'

const toast = useToast()
const { t } = i18n.global

export function createAddAttachmentsToServiceIssue() {
  return async (issueIdOrKey: string, files: VueUploadItem[]) => {
    for (const item of files) {
      if (item.file) {
        const data = await item.file.arrayBuffer()
        const blob = new Blob([data], { type: item.file.type })
        const file: File = new File(
          [blob],
          item.name ? item.name : `${issueIdOrKey}_file_${Math.floor(Math.random() * 100) + 1}`,
          { type: item.file.type },
        )
        const request: AddAttachmentsRequest = {
          issueIdOrKey,
          files: file,
        }
        try {
          apiBusyState.addAttachments = true
          const jiraIssueAttachments = await attachmentApi.addAttachments(request)
          if (jiraIssueAttachments.length > 0) {
            jiraIssueAttachments.forEach((f) =>
              toast.success(t('messages.viewComponents.selectedService.fileAttached', { fileName: f.filename })),
            )
          }
        } catch (error) {
          if (error instanceof Error) {
            file.size > 314572800
              ? toast.error(t('messages.viewComponents.selectedService.fileTooBig', { fileName: file.name }))
              : toast.error(`Error: ${file.name}-${error.message}`)
            console.error(`Error: ${file.name}-${error.message}`)
          } else {
            console.error(error)
            toast.error(`Error: ${file.name}-${error}`)
          }
        } finally {
          apiBusyState.addAttachments = false
        }
      }
    }
  }
}
