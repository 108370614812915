import { RobotSystem } from '@/interfaces'
import {
  AssignToSystemTypeEnum,
  ByComponentSerialNumberTypeEnum,
  NewComponentTypeEnum,
  ResponseError,
} from 'hcosmos-api-data'
import { findComponent, newComponent, updateSystemStatus } from '.'
import { assignComponentToSystem } from './assignComponentToSystem'

const checkComponent = async (
  id: string,
  type: ByComponentSerialNumberTypeEnum,
): Promise<{ exists: boolean; isAssigned: boolean; id?: string }> => {
  try {
    const component = await findComponent(id, type)
    const msg = `Senden fehlgeschlagen ${type} ${id} existiert bereits.`
    console.log(msg)
    return {
      exists: true,
      isAssigned: component.sytemId !== undefined,
      id: component.id!,
    }
  } catch (e) {
    if ((e as ResponseError).response.status == 403) {
      // NOTE: backend is returning a 403 if the component dose not exist, this is to prevent iteration.
      console.log(`Component "${id}" dose not exists: ${e}`)
      return {
        exists: false,
        isAssigned: false,
      }
    }
    throw 'Senden fehlgeschlagen: Unerwarteter Fehler "' + (e as Error).message + '"'
  }
}

/**
 *
 * @param system
 * @param panelSerial
 * @param panelModel
 * @param controlSerial
 * @param controlModel
 */
export async function bundleSystem(
  system: RobotSystem,
  panelSerial: string,
  panelModel: string,
  controlSerial: string,
  controlModel: string,
) {
  // console.debug('package system')

  // Components should not exist
  let [
    { id: panelId, exists: panelExists, isAssigned: panelAssigned },
    { id: controlId, exists: controlExists, isAssigned: controlAssigned },
  ] = await Promise.all([
    checkComponent(panelSerial, ByComponentSerialNumberTypeEnum.Hpanel),
    checkComponent(controlSerial, ByComponentSerialNumberTypeEnum.Hcontrol),
  ])

  if (panelAssigned) {
    throw new Error(`Panel ${panelSerial} bereits zugewiesen!`)
  }

  if (controlAssigned) {
    throw new Error(`Panel ${controlSerial} bereits zugewiesen!`)
  }

  if (!panelExists) {
    panelId = await newComponent(panelSerial, panelModel, NewComponentTypeEnum.Hpanel)
    console.log('Panel created')
  }

  if (!controlExists) {
    controlId = await newComponent(controlSerial, controlModel, NewComponentTypeEnum.Hcontrol)
    console.log('Control created')
  }

  //sometimes the api returns quotation marks at the beginning and the end
  if (panelId![0] == '"') {
    panelId = panelId!.substr(1, panelId!.length - 2)
  }
  if (controlId![0] == '"') {
    controlId = controlId!.substr(1, controlId!.length - 2)
  }

  await assignComponentToSystem(panelId!, system.id, AssignToSystemTypeEnum.Hpanel)
  console.log('Panel assigned')

  await assignComponentToSystem(controlId!, system.id, AssignToSystemTypeEnum.Hcontrol)
  console.log('Control assigned')

  await updateSystemStatus(system.id, 'PACKAGED')
  console.log('system status is set to packaged')
}
