/* tslint:disable */
/* eslint-disable */
/**
 * horstFXWebApi
 * Die API f�r horstFX WEB
 *
 * The version of the OpenAPI document: 1.0
 * Contact: alexander.jaeger@fruitcore.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerInformation,
    CustomerInformationFromJSON,
    CustomerInformationToJSON,
    InboundLink,
    InboundLinkFromJSON,
    InboundLinkToJSON,
} from '../models';

export interface DeleteRequest {
    id: string;
}

export interface CreateRequest {
    customerInformation: CustomerInformation;
}

export interface Create1Request {
    id: string;
}

export interface GetRequest {
    id: string;
}

/**
 * InboundLinkControllerApi - interface
 * 
 * @export
 * @interface InboundLinkControllerApiInterface
 */
export interface InboundLinkControllerApiInterface {
    /**
     * Deletes a single Inbound link
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundLinkControllerApiInterface
     */
    _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<string>>;

    /**
     * Deletes a single Inbound link
     */
    _delete(requestParameters: DeleteRequest): Promise<string>;

    /**
     * Creates a new inbound link
     * @param {CustomerInformation} customerInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundLinkControllerApiInterface
     */
    createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<string>>;

    /**
     * Creates a new inbound link
     */
    create(requestParameters: CreateRequest): Promise<string>;

    /**
     * Creates a new horstFXWeb instance for a given inbound link
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundLinkControllerApiInterface
     */
    create1Raw(requestParameters: Create1Request): Promise<runtime.ApiResponse<string>>;

    /**
     * Creates a new horstFXWeb instance for a given inbound link
     */
    create1(requestParameters: Create1Request): Promise<string>;

    /**
     * Retrieves a single Inbound link
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundLinkControllerApiInterface
     */
    getRaw(requestParameters: GetRequest): Promise<runtime.ApiResponse<InboundLink>>;

    /**
     * Retrieves a single Inbound link
     */
    get(requestParameters: GetRequest): Promise<InboundLink>;

    /**
     * Lists all active inbound links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundLinkControllerApiInterface
     */
    listRaw(): Promise<runtime.ApiResponse<object>>;

    /**
     * Lists all active inbound links
     */
    list(): Promise<object>;

}

/**
 * 
 */
export class InboundLinkControllerApi extends runtime.BaseAPI implements InboundLinkControllerApiInterface {

    /**
     * Deletes a single Inbound link
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/inbound/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a single Inbound link
     */
    async _delete(requestParameters: DeleteRequest): Promise<string> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new inbound link
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.customerInformation === null || requestParameters.customerInformation === undefined) {
            throw new runtime.RequiredError('customerInformation', 'Required parameter requestParameters.customerInformation was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/inbound`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerInformationToJSON(requestParameters.customerInformation),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new inbound link
     */
    async create(requestParameters: CreateRequest): Promise<string> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new horstFXWeb instance for a given inbound link
     */
    async create1Raw(requestParameters: Create1Request): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling create1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/inbound/{id}/instance`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new horstFXWeb instance for a given inbound link
     */
    async create1(requestParameters: Create1Request): Promise<string> {
        const response = await this.create1Raw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves a single Inbound link
     */
    async getRaw(requestParameters: GetRequest): Promise<runtime.ApiResponse<InboundLink>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling get.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/inbound/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InboundLinkFromJSON(jsonValue));
    }

    /**
     * Retrieves a single Inbound link
     */
    async get(requestParameters: GetRequest): Promise<InboundLink> {
        const response = await this.getRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all active inbound links
     */
    async listRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/inbound`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Lists all active inbound links
     */
    async list(): Promise<object> {
        const response = await this.listRaw();
        return await response.value();
    }

}