
/* eslint-disable vue/no-setup-props-destructure */
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'Accordion',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    open: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup({ open }) {
    const isOpen = ref(false)
    if (open) {
      isOpen.value = open
    }

    // Transition hooks
    function enter(el: HTMLElement) {
      el.style.height = 'auto'
      const height = getComputedStyle(el).height
      el.style.height = '0'
      el.style.overflow = 'hidden'

      getComputedStyle(el)
      setTimeout(() => {
        el.style.height = height
      })
    }

    function afterEnter(el: HTMLElement) {
      el.style.height = 'auto'
      el.style.overflow = 'auto'
    }

    function leave(el: HTMLElement) {
      el.style.height = getComputedStyle(el).height
      el.style.overflow = 'hidden'
      getComputedStyle(el)
      setTimeout(() => {
        el.style.height = '0'
      })
    }

    return { isOpen, enter, afterEnter, leave }
  },
})
