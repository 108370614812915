import { systemApi } from "@/data"
import { RobotSystem } from "@/interfaces"
import { UserCredentials } from "hcosmos-api-data"

export async function resetPassword(id: string): Promise<UserCredentials> {
  try {
    const creds = await systemApi.resetPassword({
      id: id,
    })
    return creds
  } catch (e: unknown) {
    throw (
      'Senden fehlgeschlagen: Passwort konnte nicht zurückgesetzt werden: ' +
      (e as Response).status +
      (e as Response).statusText
    )
  }
}
