import { AuthenticationInformation } from '@/oidc'
import { apiHost } from '@/utils/endpoints'

const endpoint = apiHost() + '/api/v1/content'

export interface AssetFetchError {
  message: string
  errorCode: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serverResponse: any
}

interface UrlAssetData {
  url: string
  tags: ReadonlyArray<string>
  assetId: string
}

export async function loadUrlTemplate(url: string, user: AuthenticationInformation): Promise<Blob> {
  if (url.startsWith('asset://')) {
    console.log('loading asset ' + url)
    const resource = url.substring(8, 44)
    const path = url.substring(44)
    return loadAsset(resource, path, user)
  } else {
    console.log('loading url:' + url)
    const info = await loadUrlAsset(url, user)
    return loadAsset(info.assetId, '/', user)
  }
}

async function loadUrlAsset(url: string, user: AuthenticationInformation): Promise<UrlAssetData> {
  return fetchRequest(prepareRequest('/url' + url, true, user), true) as Promise<UrlAssetData>
}

async function loadAsset(resource: string, path: string, user: AuthenticationInformation): Promise<Blob> {
  const request = prepareRequest('/data/' + resource + path, false, user)
  return fetchRequest<void>(request, false) as Promise<Blob>
}

async function fetchRequest<R>(request: Request, json: boolean): Promise<Blob | R> {
  const response = await fetch(request)

  if (response.status === 200) {
    return json ? response.json() : response.blob()
  } else if (response.status === 404) {
    throw {
      message: 'Diesen Artikel scheint es nicht zu geben',
      errorCode: 404,
      serverResponse: { message: '' },
    }
  } else if (response.status === 401 || response.status === 403) {
    throw {
      message: 'Kein Zugriff',
      errorCode: 403,
      serverResponse: await response.json(),
    }
  } else {
    return new Blob(
      [
        `<h1 key="${response.status}">Es ist ein Serverfehler aufgetreten (Fehlernummer zur Diagonse:${response.status})</h1>`,
      ],
      { type: 'application/html' },
    )
  }
}

function prepareRequest(url: string, json: boolean, user: AuthenticationInformation): Request {
  const request = new Request(endpoint + url)
  if (user.isAuthenticated && user.user) {
    request.headers.set('Authorization', 'Bearer ' + user.user.access_token)
  }

  if (json) {
    request.headers.set('Accept', 'application/json')
  }

  return request
}

export function getAssetPath(path: string): string {
  return endpoint + '/data/' + path
}
