import { RobotSystem } from '@/interfaces'
import { AttributeUpdateTypeEnum } from 'hcosmos-api-data'
import { systemApi } from '..'
import { updateSystemStatus } from './updateSystemStatus'

/**
 *
 * @param system
 * @param erpOrderId
 * @param erpCustomerId
 * @param jiraIssue
 */
export async function shipSystem(
  system: RobotSystem,
  erpOrderId: string,
  erpCustomerId: string,
  jiraIssue: string,
): Promise<void> {
  console.log('shipping system')

  await assignToOrder(erpOrderId, system)
  await assignToCustomer(erpCustomerId, system)
  await setJiraIssue(system, jiraIssue)
  await updateSystemStatus(system.id, 'ACTIVE')
  console.log('system status set to active')
}

async function assignToOrder(erpOrderId: string, system: RobotSystem) {
  try {
    await systemApi.assignToOrder({
      requestBody: [erpOrderId],
      id: system.id,
    })
  } catch (e) {
    throw `Senden fehlgeschlagen: xentral-auftrag konnte nicht gesetzt werden: ${+(e as Response).status}${
      (e as Response).statusText
    }`
  }
  console.log('erp order assigned')
}

async function assignToCustomer(erpCustomerId: string, system: RobotSystem) {
  try {
    await systemApi.assignToCustomer({
      requestBody: [erpCustomerId],
      id: system.id,
    })
  } catch (e) {
    throw `Senden fehlgeschlagen: xentral-kunde konnte nicht gesetzt werden: ${+(e as Response).status}${
      (e as Response).statusText
    }`
  }
  console.log('erp customer assigned')
}

async function setJiraIssue(system: RobotSystem, jiraIssue: string) {
  try {
    await systemApi.updateAttribute1({
      id: system.id,
      attributeUpdate: {
        type: AttributeUpdateTypeEnum.JiraIssue,
        value: jiraIssue,
      },
    })
  } catch (e) {
    throw `Senden fehlgeschlagen: Jira-Issue konnte nicht gesetzt werden: ${(e as Response).status}${
      (e as Response).statusText
    }`
  }
}
