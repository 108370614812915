import { systemApi } from '@/data'
import { mapProcessData } from '@/data/processData'
import { ProcessData } from '@/interfaces'
import { LoadProcessDataOptions } from '@/types'
import { markBusy } from '@/utils/helpers'
import {
  AggregatedStatisticsDouble,
  GetUserDataAggregationMethodEnum,
  GetUserDataAggregationUnitEnum,
} from 'hcosmos-api-data'

export function loadProcessData() {
  return markBusy(
    'processData',
    async (id: string, { category, key, start, end, method, unit }: LoadProcessDataOptions): Promise<ProcessData[]> => {
      let aggregationMethod = method
      let aggregationUnit = unit

      // If either one of unit or method is 'None' the other should be none too receive
      if (
        aggregationMethod === GetUserDataAggregationMethodEnum.None ||
        aggregationUnit === GetUserDataAggregationUnitEnum.None
      ) {
        aggregationMethod = GetUserDataAggregationMethodEnum.None
        aggregationUnit = GetUserDataAggregationUnitEnum.None
      }

      const res: AggregatedStatisticsDouble = await systemApi.getUserData({
        id,
        category,
        key,
        start: start.toJSDate(),
        end: end.toJSDate(),
        aggregationInterval: 1,
        aggregationMethod,
        aggregationUnit,
      })

      return mapProcessData(res)
    },
  )
}
