import { ILocationOptions } from '@/interfaces'

/**
 * NOTE: Locale language tag set to en-GB language
 */
export const enLocationOptions = {
  language: 'en',
  languageTag: 'en-GB',
  languageTagUnderline: 'en_GB',
  flatPickrDateFormat: 'd.m.Y H:i',
  flatPickrDateFormatWithoutTime: 'd.m.Y',
  flatPickrLang: 'en',
  luxonDateFormat: 'dd.LL.yyyy HH:mm',
  luxonDateFormatShort: 'dd.LL.yy HH:mm',
  luxonDateFormatTimeOfDay: 'ccc dd.LL HH:mm',
  luxonDateFormatFull: 'dd.LL.yyyy HH:mm:ss',
  luxonDateFormatExtended: 'dd.LL.yy HH:mm:ss.SSS',
  luxonFileDateFormat: 'dd-LL-yyyy',
  luxonDateFormatWithoutTime: 'dd.LL.yyyy',
  time24hr: false,
} as ILocationOptions
