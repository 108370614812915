import { RobotModel } from '@/interfaces'

export const HORST_1400: RobotModel = {
  id: '79918814-254e-4687-a4b2-7e4f99e8008c',
  name: 'HORST 1400',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/1400.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/1400.png`, import.meta.url).href,
  },
  axisMaxRotations: [3_741_000, 4_669_000, 5_291_000, 9_969_000, 7_778_000, 10_906_000],

  selectable: true,
}
