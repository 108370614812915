import { issueApi } from '@/data'
import { FormInputsServiceIssueTicket } from '@/interfaces'
import i18n from '@/locales/i18n'
import { CreateRequest, JiraIssue } from 'hcosmos-api-service'
import { useToast } from 'vue-toastification'

const toast = useToast()
const { t } = i18n.global

export function createServiceIssueTicket() {
  return async (serviceIssueTicketValues: FormInputsServiceIssueTicket) => {
    const jiraIssue = (): JiraIssue => ({
      fields: {
        project: { key: 'HSERVICE' },
        issuetype: { name: 'Service Request' },
        summary: serviceIssueTicketValues.summary,
        description: serviceIssueTicketValues.pattern,
        robotSystemId: serviceIssueTicketValues.robotId,
        horstFXVersion: serviceIssueTicketValues.horstFXVersion,
        q0horstFXErrorMessage: serviceIssueTicketValues.horstFXErrorMessage.join(' '),
        q1ErrorPattern: serviceIssueTicketValues.pattern,
        q2ErrorTime: serviceIssueTicketValues.errorTime,
        q3ErrorFrequency: { value: serviceIssueTicketValues.errorFrequency },
        q4ErrorReplication: serviceIssueTicketValues.errorReplication,
        q5ErrorDescriptionCheckboxes: serviceIssueTicketValues.errorCheckboxes.map((item) => {
          return { value: item.trim() }
        }),
        q6ErrorConfirmation: {
          value: serviceIssueTicketValues.errorConfirmation,
        },
        contactPhone: serviceIssueTicketValues.contactPhone,
        contactEmail: serviceIssueTicketValues.contactEmail,
        contactName: serviceIssueTicketValues.contactName,
        robotStreet: serviceIssueTicketValues.robotStreet,
        robotStreetNumber: serviceIssueTicketValues.robotStreetNumber,
        robotZip: serviceIssueTicketValues.robotZip,
        robotCity: serviceIssueTicketValues.robotCity,
      },
    })

    const createJiraIssueRequest: CreateRequest = {
      jiraIssue: jiraIssue(),
    }

    try {
      const response = await issueApi.create(createJiraIssueRequest)
      return response.key
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
    }
  }
}
