import { userApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { updateSystemStatus } from '@/data/systems'
import { HORST_900 } from '@/data/models/robotModels'
import { resetPassword } from './resetPassword'
import { useToast } from 'vue-toastification'
import { reset } from 'axe-core'

export function createSaveControlOnlySystem() {
  const toast = useToast()
  const { t } = i18n.global

  return async (hcontrolSerial: string, isInternal: boolean) => {
    try {

      const responseCreation = await userApi.newUserWithConrtol({
        robotSystemInfo: {
          hcontrolSerialNumber: hcontrolSerial,
          hcontrolModel: HORST_900.id,
        },
        isInternal: isInternal
      })
      
      const id = responseCreation.uuid

      if (!id) {
        throw new Error('No id returned from the server')
      }

      await updateSystemStatus(id, 'PACKAGED')
      const responsePasswordReset = await resetPassword(id)

      toast.success(`hControl ${hcontrolSerial} erfolgreich angelegt`)

      return responsePasswordReset
    } catch (e) {
      console.error(e)

      const response = (e as ResponseError).response

      if (response && (e as ResponseError).response.status === 409) {
        toast.error(t('errors.controlAlreadyExists'))
      } else {
        toast.error(t('errors.default'))
        console.error('An error occured while saving the control only system: ', e)
      }
    }
  }
}
