import { RobotModel } from '@/interfaces'

//NOTE: Regular horst 900
export const HORST_900: RobotModel = {
  id: '03eb19e4-74f3-483b-aad8-49e9dda2fd71',
  name: 'HORST 900',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/900.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/900.png`, import.meta.url).href,
  },
  axisMaxRotations: [9_036_000, 5_361_000, 7_464_000, 6_666_000, 6_986_000, 24_554_000],

  selectable: true,
}
