import { DataMode } from '@/enums'
import { ILocationOptions, IUIOptions } from '@/interfaces'
import { App, reactive } from 'vue'
import { deLocationOptions } from './deLocationOptions'
import { modalsIdList } from './modalsIdList'

const ui = reactive({
  isSidebarOpen: false,
  isSubMenuSidebarOpen: false,
  isModalOpen: modalsIdList,
  theme: 'light',
  toggleSidebar,
  toggleModal,
  changeLanguageLocationOptions,
  dataMode: process.env.VUE_APP_DEV_ENV === 'development' ? DataMode.REAL : DataMode.REAL,
  dummyDelayTime: 2500,
  location: deLocationOptions, // by default language selected is German
  mobileRoutes: ['HomeMobile', 'HorstFXWeb'],
}) as IUIOptions

export default {
  install(app: App): void {
    app.provide('ui', ui)
  },
}

function toggleModal(selectedModal: string) {
  ui.isModalOpen[selectedModal] = !ui.isModalOpen[selectedModal]
}

function toggleSidebar() {
  ui.isSidebarOpen = !ui.isSidebarOpen
}

function changeLanguageLocationOptions(languageLocationOptions: ILocationOptions) {
  ui.location = languageLocationOptions
}

export function uiOptions(): IUIOptions {
  return ui
}

export { deLocationOptions } from './deLocationOptions'
export { enLocationOptions } from './enLocationOptions'
