import { organizationsAPI } from '@/data'
import { Organization } from '@/interfaces'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'

type InviteParameters = { firstName: string; lastName: string; email: string }

const toast = useToast()
const { t, te } = i18n.global

/**
 *
 * @param organizationId
 * @param param1
 * @returns
 */
export const createSendInvite = function (organizationRef: Ref<undefined | Organization>) {
  return async ({ firstName, lastName, email }: InviteParameters) => {
    if (organizationRef.value === undefined) {
      return null
    }
    try {
      const createdIds = await organizationsAPI.addInvites({
        id: organizationRef.value.id,
        userInformation: [
          {
            email,
            firstName,
            lastName,
          },
        ],
      })
      return createdIds[0]
    } catch (error) {
      console.error(error)
      const status = (error as ResponseError).response.status
      const path = `messages.permissions.members.actions.inviteUser.errors.${status}`
      toast.error(te(path) ? t(path) : t('errors.default'))
    }
  }
}
