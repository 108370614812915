import { hfxVersionAPI } from '@/data'
import { HfxVersion } from '@/interfaces'
import { DateTime } from 'luxon'
import { Ref } from 'vue'

async function loadFCUVersion(fcuVersionRer: Ref<HfxVersion | null>) {
  if (fcuVersionRer.value == null) {
    const version = await hfxVersionAPI.getInstallVersion()
    if (!version) {
      return null
    }
    fcuVersionRer.value = {
      languages: [],
      version: version.version!,
      releaseDate: DateTime.fromJSDate(version.releaseDate!),
      current: false,
      languageInsensitiv: true,
    }
  }

  return fcuVersionRer.value
}

export { loadFCUVersion }
