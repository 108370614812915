/* tslint:disable */
/* eslint-disable */
/**
 * horstCOSMOS Data APIS
 * Die API für das Data Backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: alexander.jaeger@fruitcore.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

 import { exists, mapValues } from '../runtime';
 /**
  * 
  * @export
  * @interface CustomerInformation
  */
 export interface CustomerInformation {
     /**
      * 
      * @type {string}
      * @memberof CustomerInformation
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof CustomerInformation
      */
     id?: string;
 }
 
 export function CustomerInformationFromJSON(json: any): CustomerInformation {
     return CustomerInformationFromJSONTyped(json, false);
 }
 
 export function CustomerInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerInformation {
     if ((json === undefined) || (json === null)) {
         return json;
     }
     return {
         
         'name': !exists(json, 'name') ? undefined : json['name'],
         'id': !exists(json, 'id') ? undefined : json['id'],
     };
 }
 
 export function CustomerInformationToJSON(value?: CustomerInformation | null): any {
     if (value === undefined) {
         return undefined;
     }
     if (value === null) {
         return null;
     }
     return {
         
         'name': value.name,
         'id': value.id,
     };
 }
 