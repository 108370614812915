import { RobotSystem, ServiceIssue } from '@/interfaces'
import {
  JiraIssueAttachment,
  JiraIssueComment,
  JiraIssueErrorDescriptionCheckbox,
  JiraIssueWithHeader,
  TableJiraIssue,
} from 'hcosmos-api-service'
import { DateTime } from 'luxon'
import { stringOrDefault, dateOrDefault, dataOrDefault } from '@/utils/helpers'
import { TableServiceIssue } from '@/interfaces/TableServiceIssue'

/**
 * Maps a JiraIssueWithHeader from the api to a local ServiceIssue.
 * @param jiraIssueWithHeader
 * @param robotSystem
 * @param issueIdOrKey
 * @returns
 */
export function createServiceIssueFromApiModel(
  jiraIssueWithHeader: JiraIssueWithHeader,
  robotSystem?: RobotSystem,
  issueIdOrKey?: string,
): ServiceIssue {
  return {
    id: jiraIssueWithHeader.id,
    key: issueIdOrKey ? issueIdOrKey : (jiraIssueWithHeader.key as string),
    systemId: dataOrDefault(robotSystem, {} as RobotSystem).id,
    systemSerialNumber: dataOrDefault(robotSystem, {} as RobotSystem).serialNumber,
    systemNickname: dataOrDefault(robotSystem?.attributes.nickname, undefined),
    robotSerialNumber: dataOrDefault(robotSystem, {} as RobotSystem).robot.serialNumber,
    systemName: dataOrDefault(robotSystem, {} as RobotSystem).robot.model.name,
    summary: stringOrDefault(jiraIssueWithHeader.fields?.summary),
    description: stringOrDefault(jiraIssueWithHeader.fields?.description),
    errorTime: dateOrDefault(jiraIssueWithHeader.fields?.q2ErrorTime),
    errorReplication: stringOrDefault(jiraIssueWithHeader.fields?.q4ErrorReplication),
    errorConfirmation: stringOrDefault(jiraIssueWithHeader.fields?.q6ErrorConfirmation?.value),
    errorCheckboxes: dataOrDefault(
      jiraIssueWithHeader.fields?.q5ErrorDescriptionCheckboxes,
      [] as Array<JiraIssueErrorDescriptionCheckbox>,
    ),
    frequency: stringOrDefault(jiraIssueWithHeader.fields?.q3ErrorFrequency?.value),
    created: dateOrDefault(jiraIssueWithHeader.fields?.created),
    updated: dateOrDefault(jiraIssueWithHeader.fields?.updated),
    status: stringOrDefault(jiraIssueWithHeader.fields?.status?.name),
    resolution: stringOrDefault(jiraIssueWithHeader.fields?.resolution?.name),
    horstFXVersion: stringOrDefault(jiraIssueWithHeader.fields?.horstFXVersion),
    horstFXErrorMessage: stringOrDefault(jiraIssueWithHeader.fields?.q0horstFXErrorMessage),
    contactPhone: stringOrDefault(jiraIssueWithHeader.fields?.contactPhone),
    contactEmail: stringOrDefault(jiraIssueWithHeader.fields?.contactEmail),
    contactName: stringOrDefault(jiraIssueWithHeader.fields?.contactName),
    robotStreet: stringOrDefault(jiraIssueWithHeader.fields?.robotStreet),
    robotStreetNumber: stringOrDefault(jiraIssueWithHeader.fields?.robotStreetNumber),
    robotZip: stringOrDefault(jiraIssueWithHeader.fields?.robotZip),
    robotCity: stringOrDefault(jiraIssueWithHeader.fields?.robotCity),
    attachments: dataOrDefault(jiraIssueWithHeader.fields?.attachment, [] as Array<JiraIssueAttachment>),
    comments: dataOrDefault(jiraIssueWithHeader.fields?.comment?.comments, [] as Array<JiraIssueComment>),
    date: DateTime.now(),
  }
}

/**
 * Maps a JiraIssueWithHeader from the api to a local ServiceIssue.
 * @param jiraIssueWithHeader
 * @param robotSystem
 * @param issueIdOrKey
 * @returns
 */
export function createTableServiceIssueFromApiModel(
  jiraIssueWithHeader: TableJiraIssue,
  robotSystem?: RobotSystem,
  issueIdOrKey?: string,
): TableServiceIssue {
  return {
    id: jiraIssueWithHeader.id,
    key: issueIdOrKey ? issueIdOrKey : (jiraIssueWithHeader.key as string),
    systemId: dataOrDefault(robotSystem, {} as RobotSystem).id,
    summary: stringOrDefault(jiraIssueWithHeader.fields?.summary),
    created: dateOrDefault(jiraIssueWithHeader.fields?.created),
    updated: dateOrDefault(jiraIssueWithHeader.fields?.updated),
    status: stringOrDefault(jiraIssueWithHeader.fields?.status?.name),
    systemSerialNumber: dataOrDefault(robotSystem, {} as RobotSystem).serialNumber,
    systemNickname: dataOrDefault(robotSystem?.attributes.nickname, undefined),
    robotSerialNumber: dataOrDefault(robotSystem, {} as RobotSystem).robot.serialNumber,
  }
}
