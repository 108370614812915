import { ErrorRateOption } from '@/types'
import { ref, Ref } from 'vue'

export const errorRateOptions: Ref<ErrorRateOption[]> = ref([
  {
    value: 'Permanent',
    label: 'Permanent',
  },
  {
    value: 'Häufiger als 1x pro Stunde',
    label: 'Häufiger als 1x pro Stunde',
  },
  {
    value: 'Häufiger als 1x pro Tag',
    label: 'Häufiger als 1x pro Tag',
  },
  {
    value: 'Häufiger als 1x pro Woche',
    label: 'Häufiger als 1x pro Woche',
  },
  {
    value: 'Häufiger als 1x pro Monat',
    label: 'Häufiger als 1x pro Monat',
  },
  {
    value: 'Seltener als 1x pro Monat',
    label: 'Seltener als 1x pro Monat',
  },
])
