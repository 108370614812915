import { systemApi } from '@/data'
import { createRobotSystemFromApiModel } from '@/data/systems'
import { RobotSystem } from '@/interfaces'
import { Ref } from 'vue'
import { loadContracts } from './loadContracts'
import { loadStatistics } from './loadStatistics'

export function createFetchSystemById(systemRef: Ref<null | RobotSystem>) {
  return async (id: string) => {
    const robot = await systemApi.byIdDetailed({ id })
    systemRef.value = createRobotSystemFromApiModel(robot)
    loadContracts(systemRef as Ref<RobotSystem>)
    loadStatistics(systemRef as Ref<RobotSystem>)
  }
}
