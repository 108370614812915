import { LicenseType, LicenseContractType } from '@/enums'
import { GroupLicense, LicenseFeatureInformation } from '@/interfaces'
import { stringOrDefault } from '@/utils/helpers'
import { lookup } from '@/utils/lookup'
import {
  LicensePackageInformationTypeEnum,
  LicensePackageInformationContractTypeEnum,
  LicensePackageInformation,
  LicenseFeatureInformation as hLicenseFeatureInformation,
} from 'hcosmos-api-data'
import { DateTime } from 'luxon'

const mapPackageType = lookup<LicensePackageInformationTypeEnum, LicenseType>(
  {
    [LicensePackageInformationTypeEnum.Group]: LicenseType.GROUP,
    [LicensePackageInformationTypeEnum.User]: LicenseType.USER,
  },
  LicenseType.GROUP,
)

const mapContractType = lookup<LicensePackageInformationContractTypeEnum, LicenseContractType>(
  {
    [LicensePackageInformationContractTypeEnum.ServiceContract]: LicenseContractType.SERVICE_CONTRACT,
    [LicensePackageInformationContractTypeEnum.Software]: LicenseContractType.SOFTWARE,
    [LicensePackageInformationContractTypeEnum.Trial]: LicenseContractType.TRIAL,
  },
  LicenseContractType.SERVICE_CONTRACT,
)

export const toGroupLicense =
  (organizationId: string) =>
  (license: LicensePackageInformation): GroupLicense => {
    return {
      jiraIssue: stringOrDefault(license.jiraIssue),
      type: mapPackageType(license.type),
      contractType: mapContractType(license.contractType),
      title: { ...license.title },
      timeStart: DateTime.fromJSDate(license.timeStart!),
      timeEnd: DateTime.fromJSDate(license.timeEnd!),
      features: [...(license.features || [])].map(toFeature),
      comment: stringOrDefault(license.comment),
      owner: stringOrDefault(license.owner, stringOrDefault(license.organization, organizationId)),
      organization: stringOrDefault(license.organization, organizationId),
      rented: [],
    }
  }

function toFeature(feature: hLicenseFeatureInformation): LicenseFeatureInformation {
  return {
    id: feature.id!,
    title: {
      ...(feature.title || {}),
    },
  }
}
