import { Member } from '@/interfaces'
import { stringOrDefault } from '@/utils/helpers'
import { UserInformation } from 'hcosmos-api-data'

export function toMember(userInformation: UserInformation): Member {
  return {
    id: stringOrDefault(userInformation.id),
    lastName: stringOrDefault(userInformation.lastName),
    firstName: stringOrDefault(userInformation.firstName),
    email: stringOrDefault(userInformation.email),
    isRobot: userInformation.userType === 'ROBOT',
    isInvited: !userInformation.emailVerified || false,
  }
}
