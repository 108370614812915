/* tslint:disable */
/* eslint-disable */
/**
 * horstFXWebApi
 * Die API f�r horstFX WEB
 *
 * The version of the OpenAPI document: 1.0
 * Contact: alexander.jaeger@fruitcore.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

 import { exists, mapValues } from '../runtime';
 import {
     CustomerInformation,
     CustomerInformationFromJSON,
     CustomerInformationFromJSONTyped,
     CustomerInformationToJSON,
 } from './';
 
 /**
  * A link through which the customer can reach horstfxweb
  * @export
  * @interface InboundLink
  */
 export interface InboundLink {
     /**
      * 
      * @type {string}
      * @memberof InboundLink
      */
     id: string;
     /**
      * 
      * @type {CustomerInformation}
      * @memberof InboundLink
      */
     recepient: CustomerInformation;
     /**
      * 
      * @type {string}
      * @memberof InboundLink
      */
     status: InboundLinkStatusEnum;
 }
 
 export function InboundLinkFromJSON(json: any): InboundLink {
     return InboundLinkFromJSONTyped(json, false);
 }
 
 export function InboundLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboundLink {
     if ((json === undefined) || (json === null)) {
         return json;
     }
     return {
         
         'id': json['id'],
         'recepient': CustomerInformationFromJSON(json['recepient']),
         'status': json['status'],
     };
 }
 
 export function InboundLinkToJSON(value?: InboundLink | null): any {
     if (value === undefined) {
         return undefined;
     }
     if (value === null) {
         return null;
     }
     return {
         
         'id': value.id,
         'recepient': CustomerInformationToJSON(value.recepient),
         'status': value.status,
     };
 }
 
 /**
 * @export
 * @enum {string}
 */
 export enum InboundLinkStatusEnum {
     ACTIVE = 'ACTIVE',
     DELETED = 'DELETED',
     EXPIRED = 'EXPIRED'
 }
 
 