import { organizationsAPI } from '@/data'

import { Organization } from '@/interfaces'
import { Ref } from 'vue'
import { toOrganization } from '../organizations/_toOrganization'

export function createLoadOrganisation(
  orgRef: Ref<undefined | Organization>,
  ...sideEffects: (() => Promise<unknown>)[]
) {
  return async (id: string) => {
    try {
      const res = await organizationsAPI.getOrganization({ id })
      orgRef.value = toOrganization(res)
      sideEffects.forEach((f) => f())
      return orgRef.value
    } catch (e) {
      console.error(e)
    }
  }
}
