import { systemApi } from '@/data'
import { Contract } from '@/interfaces'
import { CONTRACT_FEATURE_TYPE } from '@/enums'
import { RobotSystem } from '@/interfaces'
import { LicensePackageInformationContractTypeEnum } from 'hcosmos-api-data'

import { DateTime } from 'luxon'
import { Ref } from 'vue'

export async function loadContracts(system: Ref<RobotSystem>) {
  const res = await systemApi.listContracts({ id: system.value.id })
  system.value.contracts = []
  for (const contract of res) {
    const parsedContract: Contract = {
      start: DateTime.fromJSDate(contract.contractStart),
      end: contract.contractEnd ? DateTime.fromJSDate(contract.contractEnd) : undefined,
      title: contract.title ? contract.title : contract.jiraIssue ? contract.jiraIssue : '',
      jiraIssue: contract.jiraIssue ? contract.jiraIssue : '',
      type: contract.jiraIssue?.startsWith('VERTRAG')
        ? LicensePackageInformationContractTypeEnum.ServiceContract
        : LicensePackageInformationContractTypeEnum.Software,
      features: [],
    }

    //NOTE For now every contract has a waranty extension
    if (parsedContract.jiraIssue.startsWith('VERTRAG')) {
      parsedContract.features.push({
        type: CONTRACT_FEATURE_TYPE.WARRANTY_EXTENSION,
        featureStart: parsedContract.start,
        featureEnd: parsedContract.end,
      })
    }

    system.value.contracts.push(parsedContract)
  }
}
