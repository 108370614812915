import { issueApi } from '@/data'
import i18n from '@/locales/i18n'
import { AddCommentRequest, JiraIssueComment } from 'hcosmos-api-service'
import { useToast } from 'vue-toastification'

const toast = useToast()
const { t } = i18n.global

export function createAddCommentToServiceIssue() {
  return async (body: string, issueIdOrKey: string, userName: string = '') => {
    const jiraIssueComment = (): JiraIssueComment => ({
      body: userName + ': ' + body,
      visibility: {
        type: 'role',
        value: 'Service Desk Customers',
      },
    })

    const addCommentRequest: AddCommentRequest = {
      issueIdOrKey,
      jiraIssueComment: jiraIssueComment(),
    }
    try {
      await issueApi.addComment(addCommentRequest)
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
    }
    return
  }
}
