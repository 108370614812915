import { RobotModel } from '@/interfaces'

//NOTE: fast or strong old hardware model
export const HORST_600_old: RobotModel = {
  id: 'fa49e294-fbb4-4384-b822-bca55e311e28',
  name: 'HORST 600',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/600.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/600.png`, import.meta.url).href,
  },
  axisMaxRotations: [7_725_000, 6_100_000, 17_076_000, 5_171_000, 8_514_000, 22_503_000],
}
