import { NewComponentTypeEnum } from 'hcosmos-api-data'
import { componentApi } from '..'

/**
 *
 * @param serialNumber
 * @param model
 * @param type
 * @returns Promise<string>
 */
export async function newComponent(serialNumber: string, model: string, type: NewComponentTypeEnum): Promise<string> {
  try {
    return await componentApi.newComponent({
      componentInfo: {
        model,
        serialNumber,
      },
      type,
    })
  } catch (e) {
    throw new Error(`${type} konnte nicht angelegt werden: ${(e as Response).status}${(e as Response).statusText}`)
  }
}
