import { licenseApi } from '@/data'

function createRequestLicenseTicket() {
  return async (desiredStart: Date, desiredEnd: Date, clientData: string) => {
    return licenseApi.getLicense({
      licenseRequest: {
        clientData,
        desiredStart,
        desiredEnd,
      },
    })
  }
}

export { createRequestLicenseTicket }
