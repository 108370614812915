import { groupsApi } from '@/data'

import { Member } from '@/interfaces'
import { Ref } from 'vue'
import { toMember } from './_toMembers'

export const createLoadMembers = (membersRef: Ref<Map<string, Member[]>>) => {
  return async (groupId: string, revalidate: boolean = false) => {
    if (!revalidate && membersRef.value.has(groupId)) {
      return membersRef.value.get(groupId)!
    }

    console.debug(`Load members for ${groupId}`)
    const members = await groupsApi.getGroupMembers({
      id: groupId,
    })

    const mappedMembers = members.map(toMember)
    membersRef.value.set(groupId, mappedMembers)
    return mappedMembers
  }
}
