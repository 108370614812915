import { EntityState, RobotSystemComponentType, RobotSystemEventTypes } from '@/enums'
import { RobotModel, RobotSystem, RobotSystemEvent } from '@/interfaces'
import { stringOrDefault, dateOrDefault } from '@/utils/helpers'
import { lookup } from '@/utils/lookup'
import {
  DetailedExtendedRobotSystemInfo,
  ExtendedRobotSystemInfoEntityStateEnum,
  RobotSystemEventListEntry,
  RobotSystemEventListEntryComponentTypeEnum,
  RobotSystemEventListEntryEventEnum,
} from 'hcosmos-api-data'
import { DateTime } from 'luxon'
import { ROBOT_MODELS, UnknownModel } from '@/data/models/'

export function createRobotSystemFromApiModel(system: DetailedExtendedRobotSystemInfo): RobotSystem {
  const customers = (system.customers ? Array.from(system.customers) : []).map((elem) => {
    return {
      id: elem.id ? elem.id : 'Keine Kundennummer',
      name: elem.name ? elem.name : 'Kein Kundenname',
    }
  })

  return {
    serialNumber: stringOrDefault(system.systemSerialNumber),
    id: stringOrDefault(system.id),
    robot: {
      id: stringOrDefault(system.robotArmId),
      serialNumber: stringOrDefault(system.robotArmSerialNumber),
      model: getModelById(stringOrDefault(system.robotArmModel)),
    },
    control: {
      id: stringOrDefault(system.hcontrolId),
      serialNumber: stringOrDefault(system.hcontrolSerialNumber),
    },
    panel: {
      id: stringOrDefault(system.hpanelId),
      serialNumber: stringOrDefault(system.hpanelSerialNumber),
    },
    history: [...system.history!].map(mapEventEntry),
    statistics: {
      summary: {
        axisMovement: [],
        baseHoursOfMovement: 0,
        hfxHoursOfOperation: 0,
        tcpMovementSum: 0,
        hfxLaunches: 0,
        jointOverload: 0,
        movementAxisSums: [],
        hfxProgramLaunches: 0,
        telemetryUpdateTime: undefined,
      },
    },
    attributes: {
      nickname: system.attributes?.NICKNAME,
      userDescription: system.attributes?.USER_DESCRIPTION,
    },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    entityState: entityState(system.entityState),
    buildDates: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      robotArm: dateOrDefault(system.robotArmCreatedAt!),
    },
    customers: customers,
    services: [],
    contracts: [],
  }
}

function mapEventEntry(entry: RobotSystemEventListEntry): RobotSystemEvent {
  return {
    id: entry.id!,
    timestamp: dateOrDefault(entry.timestamp, DateTime.fromJSDate(new Date('2015-01-01'))),
    event: mapEventType(entry.event!),
    reference: stringOrDefault(entry.reference!, ''),
    componentReference: entry.componentReference,
    componentType: mapKomponentType(entry.componentType),
  }
}

function entityState(state: ExtendedRobotSystemInfoEntityStateEnum | undefined) {
  switch (state) {
    case ExtendedRobotSystemInfoEntityStateEnum.Active:
      return EntityState.ACTIVE
    case ExtendedRobotSystemInfoEntityStateEnum.Production:
      return EntityState.PRODUCTION
    case ExtendedRobotSystemInfoEntityStateEnum.Packaged:
      return EntityState.PACKAGED
    default:
      return EntityState.UNKNOWN
  }
}

function getModelById(id: string): RobotModel {
  const model = ROBOT_MODELS.find((model) => model.id === id)
  return model ? model : UnknownModel
}

const mapEventType = lookup<RobotSystemEventListEntryEventEnum, RobotSystemEventTypes>(
  {
    [RobotSystemEventListEntryEventEnum.ComponentAttached]: RobotSystemEventTypes.COMPONENT_ATTACHED,
    [RobotSystemEventListEntryEventEnum.ComponentRemoved]: RobotSystemEventTypes.COMPONENT_REMOVED,
    [RobotSystemEventListEntryEventEnum.Created]: RobotSystemEventTypes.CREATED,
    [RobotSystemEventListEntryEventEnum.Packaged]: RobotSystemEventTypes.PACKAGED,
    [RobotSystemEventListEntryEventEnum.Shipped]: RobotSystemEventTypes.SHIPPED,
  },
  RobotSystemEventTypes.NONE,
)

const mapKomponentType = lookup<RobotSystemEventListEntryComponentTypeEnum, RobotSystemComponentType>({
  [RobotSystemEventListEntryComponentTypeEnum.Hcontrol]: RobotSystemComponentType.HCONTROL,
  [RobotSystemEventListEntryComponentTypeEnum.Hpanel]: RobotSystemComponentType.HPANEL,
  [RobotSystemEventListEntryComponentTypeEnum.Robotarm]: RobotSystemComponentType.ROBOTARM,
})
