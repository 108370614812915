import { RobotModel } from '@/interfaces'

//NOTE: fast or strong old hardware model
export const HORST_600_old_2: RobotModel = {
  id: '3cd39849-3caa-4c23-937a-25b0a3333f07',
  name: 'HORST 600',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/600.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/600.png`, import.meta.url).href,
  },
  axisMaxRotations: [7_725_000, 6_100_000, 17_076_000, 5_171_000, 8_514_000, 22_503_000],
}
