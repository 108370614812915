import { authorityHost, callbackHost } from '@/utils/endpoints'
import { url } from '@vuelidate/validators'
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { App, reactive } from 'vue'
import { event } from 'vue-gtag'

export type UserChangeHandler = (user: User | null) => void

const messageSource = 'oidc-client'

export interface AuthenticationInformation {
  initialized: boolean
  isAuthenticated: boolean
  isInternalUser: boolean
  toggleAuthentication: () => void
  user: User | null
  login: (state?: string) => void
  logout: () => void
  addUserChangeHandler: (handler: UserChangeHandler) => void
  removeUserChangeHandler: (handler: UserChangeHandler) => void
}

const redirectUri = callbackHost() + '/oidc-callback.html'
const responseType = 'code'
const scope = 'openid profile email groups'

export const userManager = new UserManager({
  authority: authorityHost() + '/auth/realms/horstcosmos/',
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: callbackHost() + '/',
  response_type: responseType,
  loadUserInfo: true,
  scope: scope,
  silent_redirect_uri: callbackHost() + '/oidc-silent-callback.html',
  silentRequestTimeoutInSeconds: 5,
  monitorSession: true,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
})

const userChangeHandler: UserChangeHandler[] = []

export const oidcEntity: AuthenticationInformation = reactive({
  initialized: false,
  isAuthenticated: false,
  isInternalUser: false,
  toggleAuthentication: () => toggleAuthentcation(oidcEntity),
  user: null,
  login,
  logout,
  addUserChangeHandler: (handler: UserChangeHandler) => {
    userChangeHandler.push(handler)
  },
  removeUserChangeHandler: (handler: UserChangeHandler) => {
    userChangeHandler.splice(userChangeHandler.indexOf(handler), 1)
  },
})

export default {
  install(app: App): void {
    app.provide('oidc', oidcEntity)
    // we need to call this function locally otherwise the user loaded event will never be triggered
    userManager.events.addUserLoaded((user) => userLoaded(user))
    userManager.events.removeUserLoaded((user) => userLoaded(user))
    userManager.events.addSilentRenewError(() => silentRenewError())
    userManager.events.addUserSessionChanged(() => userSessionChanged())
    userManager.events.addUserSignedOut(() => userLoaded(null))
    userManager.getUser().then((user) => {
      if (!user) {
        userManager
          .signinSilent()
          .then((user) => {
            event('user-silent-login-successful', {
              event_category: 'user-action',
              event_label: 'Benutzer eingeloggt',
            })
            userLoaded(user)
          })
          .catch((err) => {
            event('user-silent-login-failed', {
              event_category: 'user-action',
              event_label: 'Stilles einloggen fehlgeschlagen',
            })
            console.log('silent signin failed ' + err)
            userLoaded(null)
          })
      } else {
        userManager
          .signinSilent()
          .then((user) => {
            event('user-silent-login-successful', {
              event_category: 'user-action',
              event_label: 'Benutzer eingeloggt',
            })
            userLoaded(user)
          })
          .catch(() => {
            event('user-silent-login-failed-with-expired-session', {
              event_category: 'user-action',
              event_label: 'Stilles einloggen fehlgeschlagen wegen abgelaufener Session',
            })
            console.log('silent signin failed session probably expired')
            userLoaded(null)
          })
      }
    })
  },
}

function toggleAuthentcation(oidc: AuthenticationInformation) {
  oidc.isAuthenticated = !oidc.isAuthenticated
}

function login(state = window.location.href) {
  console.log('login')
  userManager.signinRedirect({ state })
  event('user-login-clicked', {
    event_category: 'user-action',
    event_label: 'Benutzer hat den Login Button angeklickt',
  })
}

function logout() {
  console.log('logout')

  const id_token = oidcEntity.user?.id_token
  userManager.removeUser()
  if (!id_token) {
    userManager.signoutRedirect()
  } else {
    userManager.signoutRedirect({ id_token_hint: id_token })
  }
  oidcEntity.isAuthenticated = false
  oidcEntity.user = null
  event('user-logout-clicked', {
    event_category: 'user-action',
    event_label: 'Benutzer hat den Logout Button angeklickt',
  })
}

function userLoaded(user: User | null) {
  oidcEntity.isAuthenticated = user !== null ? true : false
  oidcEntity.user = user
  oidcEntity.initialized = true

  if (user) {
    const groups = user.profile.groups as string[]
    oidcEntity.isInternalUser = groups.includes('/Organisationen/fruitcore robotics GmbH/Mitarbeiter')
  } 

  for (const handler of userChangeHandler) {
    handler(user)
  }
}

function silentRenewError() {
  console.log('silent Renew failed')
  userManager.stopSilentRenew()
  oidcEntity.isAuthenticated = false
  oidcEntity.user = null
}

function userSessionChanged() {
  console.log('user session changed')
}

export function getUserDirect() {
  return oidcEntity
}
