import { errorRateOptions, errorCheckboxesValues, positionOptions } from '@/data/serviceIssues'
import { ServiceIssue } from '@/interfaces'
import { faker } from '@faker-js/faker'
import { DateTime } from 'luxon'
import { IssueStatus } from '@/enums'
import { JiraIssueAttachment, JiraIssueComment } from 'hcosmos-api-service'
import { horstFXErrors, horstFXVersions } from '@/data/systems'
import { helpers } from '@vuelidate/validators'

export function generateServiceIssueDummies(serialNumber?: string, id?: string): ServiceIssue[] {
  const dummyServiceIssues: ServiceIssue[] = []

  if (serialNumber && id) {
    while (dummyServiceIssues.length < 15) {
      dummyServiceIssues.push(createServiceIssueDummy(faker.datatype.uuid(), serialNumber, id))
    }
    return dummyServiceIssues
  }
  while (dummyServiceIssues.length < 15) {
    dummyServiceIssues.push(createServiceIssueDummy())
  }
  return dummyServiceIssues
}

/**
 *
 * @param id
 * @param systemSerialNumber
 * @param systemId
 */
export function createServiceIssueDummy(id?: string, systemSerialNumber?: string, systemId?: string): ServiceIssue {
  return {
    id: id ? id : faker.datatype.uuid(),
    key: `HSERVICE-${faker.lorem.slug()}`,
    systemId: systemId ? systemId : faker.datatype.uuid(),
    systemSerialNumber: systemSerialNumber ? systemSerialNumber : faker.datatype.string(8),
    robotSerialNumber: faker.datatype.string(9),
    systemName: faker.company.bsBuzz(),
    summary: faker.lorem.sentence(),
    description: faker.lorem.paragraphs(2),
    errorTime: DateTime.fromJSDate(faker.date.recent(20)),
    errorReplication: positionOptions[Math.floor(Math.random() * 4)],
    errorConfirmation: Math.random() > 0.6 ? 'Ja' : 'Nein',
    errorCheckboxes: faker.helpers.arrayElements(errorCheckboxesValues, 3),
    frequency: errorRateOptions.value[Math.floor(Math.random() * 6)].label,
    created: DateTime.fromJSDate(faker.date.recent(Math.floor(Math.random() * 20) + 1)),
    updated: DateTime.fromJSDate(faker.date.recent(Math.floor(Math.random() * 6) + 1)),
    resolution: faker.lorem.word(),
    contactName: faker.name.fullName(),
    contactEmail: faker.internet.email(),
    contactPhone: faker.phone.number(),
    robotCity: faker.address.city(),
    robotStreet: faker.address.streetName(),
    robotStreetNumber: faker.address.buildingNumber(),
    robotZip: faker.address.zipCode(),
    status: createStatusDummy(),
    horstFXVersion: horstFXVersions[Math.floor(Math.random() * horstFXVersions.length)],
    horstFXErrorMessage: horstFXErrors[Math.floor(Math.random() * horstFXErrors.length)],
    attachments: [] as JiraIssueAttachment[],
    comments: createArrayCommentDummies(),
    date: DateTime.fromJSDate(faker.date.recent(4)),
  } as ServiceIssue
}

function createArrayCommentDummies(): JiraIssueComment[] {
  const dummyComments: JiraIssueComment[] = []
  const limitValue = faker.datatype.number({
    min: 10,
    max: 25,
  })
  while (dummyComments.length < limitValue) {
    dummyComments.push(createCommentDummy())
  }
  return dummyComments
}

export function createCommentDummy(): JiraIssueComment {
  return {
    id: faker.datatype.uuid(),
    body: faker.lorem.sentence(Math.floor(Math.random() * 6) + 1),
    created: faker.date.recent(7),
    updated: faker.date.recent(2),
    author:
      Math.random() > 0.6
        ? {
            key: faker.datatype.uuid(),
            displayName: faker.name.firstName(),
            emailAddress: faker.internet.email(),
          }
        : {
            key: faker.datatype.uuid(),
            displayName: faker.name.firstName(),
            emailAddress: 'service@fruitcore.de',
          },
  }
}

function createStatusDummy(): ServiceIssue['status'] {
  const randomValue = Math.floor(Math.random() * 4)
  switch (randomValue) {
    case 0:
      return IssueStatus.RESOLVED
    case 1:
      return IssueStatus.OPEN
    case 2:
      return IssueStatus.IN_PROGRESS
    case 3:
      return IssueStatus.WAITING_FOR_CUSTOMER
    default:
      return IssueStatus.NotApplicable
  }
}
