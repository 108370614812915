import { systemApi } from '@/data'

export function createReleaseLicenses() {
  return async (systemId: string) => {
    try {
      return await systemApi.releaseLicenses({
        id: systemId,
      })
    } catch (e) {
      throw new Error(`Senden fehlgeschlagen: Lizenzen konnte nicht freigegeben werden`, { cause: e })
    }
  }
}
