import { licenseApi } from '@/data'
import { apiBusyState } from '@/data/states'
import { GetSignedLicenseRequest } from 'hcosmos-api-data'

export function createLoadSignedSystemLicense() {
  return async (clientId: string, robotsystemID: string) => {
    const licenseRequest = { start: new Date(), end: new Date(), clientId, robotsystemID } as GetSignedLicenseRequest
    apiBusyState.packageInformationLicenses = true
    const signedLicense = await licenseApi.getSignedLicense(licenseRequest)
    apiBusyState.packageInformationLicenses = false
    return signedLicense
  }
}
