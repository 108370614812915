import { userApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'

import { useToast } from 'vue-toastification'

export function createSaveProductionSystem() {
  const toast = useToast()
  const { t } = i18n.global
  return async (robotSerial: string, robotModel: string) => {
    try {
      await userApi.newUser({
        robotSystemInfo: {
          robotArmModel: robotModel,
          robotArmSerialNumber: robotSerial,
        },
      })
    } catch (e) {
      console.error(e)

      if ((e as ResponseError).response.status === 409) {
        toast.error(t('errors.robotAlreadyExists'))
      } else {
        toast.error(t('errors.default'))
      }

      throw e
    }
  }
}
