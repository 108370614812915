/*=====  End of Table Interfaces Definitions  ======*/
/*=============================================
=            RobotSystem Interface Definitions            =
=============================================*/

export enum RobotSystemEventTypes {
  CREATED = 'CREATED',
  COMPONENT_ATTACHED = 'COMPONENT_ATTACHED',
  COMPONENT_REMOVED = 'COMPONENT_REMOVED',
  PACKAGED = 'PACKAGED',
  SHIPPED = 'SHIPPED',
  NONE = 'N/A',
}
