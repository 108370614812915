import { groupsApi } from '@/data'
import { Subgroup } from '@/interfaces'
import i18n from '@/locales/i18n'
import { dataOrDefault, stringOrDefault } from '@/utils/helpers'
import { GroupListEntry, ResponseError } from 'hcosmos-api-data'
import { useToast } from 'vue-toastification'

function toGroup(groupListEntry: GroupListEntry): Subgroup {
  return {
    id: stringOrDefault(groupListEntry.id),
    name: stringOrDefault(groupListEntry.name),
    attributes: dataOrDefault(groupListEntry.attributes, {}),
  }
}

export function createCreateGroup(...sideEffects: ((groupId: string) => Promise<unknown>)[]) {
  const toast = useToast()
  const { te, t } = i18n.global

  return async (id: string, ...names: string[]) => {
    const body = new Set<string>()

    names.forEach((name) => body.add(name))

    try {
      const groups = await groupsApi.addSubgroups({
        id,
        requestBody: names,
      })

      sideEffects.forEach((f) => f(id))
      return groups.map(toGroup)
    } catch (e) {
      console.error(e)
      const responseError = e as ResponseError
      const messagePath = `messages.permissions.subgroups.actions.add.error.${responseError.response.status}`
      toast.error(te(messagePath) ? t(messagePath) : t('errors.default'))
    }

    return []
  }
}
