import { groupsApi } from '@/data'
import { Group } from '@/interfaces'
import { dataOrDefault, stringOrDefault } from '@/utils/helpers'
import { Ref } from 'vue'

export const createLoadGroup = (groupsRef: Ref<Map<string, Group>>) => {
  return async (groupId: string, revalidate: boolean = false) => {
    if (!revalidate && groupsRef.value.has(groupId)) {
      return groupsRef.value.get(groupId)!
    }
    const response = await groupsApi.getGroup({
      id: groupId,
    })
    const isServiceGroup = response.attributes?.Service !== undefined
    const group = {
      id: stringOrDefault(response.id),
      name: stringOrDefault(response.name),
      path: stringOrDefault(response.path),
      attributes: dataOrDefault(response.attributes, {}),
      serviceGroup: isServiceGroup,
      userLimit: isServiceGroup ? Number.parseInt(dataOrDefault(response.attributes?.UserLimit[0], '0')) : undefined,
      userRoles: {
        GROUP_ADMIN: dataOrDefault(response.userRoles?.GROUP_ADMIN, []),
        ORGANIZATION_ADMIN: dataOrDefault(response.userRoles?.ORGANIZATION_ADMIN, []),
      },
    }
    groupsRef.value.set(group.id, group)
    return group
  }
}
