import { uiOptions } from '@/config'
import { RobotSystem } from '@/interfaces'
import { apiBusyState } from '../states'
import { systemDummy } from './_dummy'

export async function systemTableDummyValues(): Promise<RobotSystem[]> {
  apiBusyState.system = true
  await new Promise((resolve) => setTimeout(resolve, uiOptions().dummyDelayTime))
  const ret = []
  while (ret.length < 30) {
    ret.push(systemDummy())
  }
  apiBusyState.system = false
  return ret
}
