import { HorstFXErrorOptions } from '@/types'

export const horstFXErrors: HorstFXErrorOptions = [
  'BOOTLOADER_COMMUNICATION',
  'BOOTLOADER_CRC_APPLICATION',
  'BOOTLOADER_CRC_LAST_PACKET',
  'BOOTLOADER_DRIVER',
  'BOOTLOADER_ENCODER',
  'BOOTLOADER_FLASH_ACCESS',
  'BOOTLOADER_INVALID_DATA_PACKET',
  'BOOTLOADER_INVALID_DEVICE_REQUESTED',
  'BOOTLOADER_INVALID_DEVICE_RESPONDED',
  'BOOTLOADER_INVALID_FINAL_PACKET',
  'BOOTLOADER_INVALID_STATE',
  'BOOTLOADER_OVF_TX_BUFFER',
  'BOOTLOADER_UNEXPECTED_COMMAND',
  'BOOTLOADER_UNEXPECTED_START_ADDRESS',
  'BOOTLOADER_USER_IO',
  'BOOTLOADER_WRONG_START_ADDR',
  'BRAKE',
  'BRAKE_KS1',
  'BRAKE_KS3',
  'BUS_DRIVER_ACCELERATION_EXCEEDED',
  'BUS_DRIVER_CORRUPTED_DATA',
  'BUS_DRIVER_DISCONNECT',
  'BUS_DRIVER_GET_DEVICE_INFO_FAILED',
  'BUS_DRIVER_I2C',
  'BUS_DEVICE_ID_DEVICE_INFO_VERSION',
  'BUS_DEVICE_ID_DEVICE_SW_VERSION',
  'BUS_DRIVER_MOTOR_CONTROLLER_SPI',
  'BUS_DRIVER_OVERTEMPERATURE',
  'BUS_DRIVER_TIMINGS',
  'BUS_DRIVER_TMC_RESET',
  'BUS_DRIVER_VERSION_TOO_OLD',
  'BUS_DRIVER_VOLTAGE_BRAKE',
  'BUS_DRIVER_VOLTAGE_48V_LEVEL',
  'BUS_DRIVER_WRONG_CONFIGURATION',
  'BUS_DRIVER_WRONG_FUNC_PARAMETER',
  'BUS_ENCODER_BUFFER_FULL',
  'BUS_ENCODER_DISCONNECT',
  'BUS_ENCODER_GET_DEVICE_INFO_FAILED',
  'BUS_ENCODER_ID_OUT_OF_RANGE',
  'BUS_ENCODER_ID_READ_OFFSETS',
  'BUS_ENCODER_ID_ROBOT_MODEL',
  'BUS_ENCODER_LOST_INIT',
  'BUS_ENCODER_NO_DISC_OFFSETS_FOUND',
  'BUS_ENCODER_NO_DISC_OFFSETS_IN_DEVICE',
  'BUS_ENCODER_REV_COUNTER',
  'BUS_UNKNOWN_CMD_PREPARED',
  'BUS_USER_IO_CONFIG',
  'BUS_USER_IO_DIGITAL_OUT',
  'BUS_USER_IO_DISCONNECT',
  'BUS_USER_IO_GET_DEVICE_INFO_FAILED',
  'BUS_VERSION_LENGTH_EXCEEDED',
  'COM_HEARTBEAT_KL1_KL2',
  'COM_HEARTBEAT_KL1_KL7',
  'COM_IO_INVALID_CARD_SAFETY_OUT',
  'COM_IO_INVALID_FORMAT_CONFIG_ALL',
  'COM_IO_INVALID_FORMAT_CONFIG_DIN',
  'COM_IO_INVALID_FORMAT_CONFIG_DOUT',
  'COM_IO_INVALID_FORMAT_CONFIG_SIO',
  'COM_IO_INVALID_FORMAT_STATE_ALL',
  'COM_IO_INVALID_FORMAT_STATE_SINGLE',
  'COM_IO_MAINBOARD_INVALID_DATA_CONFIG_ALL',
  'COM_IO_MAINBOARD_INVALID_DATA_CONFIG_DIN',
  'COM_IO_MAINBOARD_INVALID_DATA_CONFIG_DOUT',
  'COM_IO_MAINBOARD_INVALID_DATA_CONFIG_SIO',
  'COM_IO_MAINBOARD_INVALID_DATA_STATE_ALL',
  'COM_IO_MAINBOARD_INVALID_DATA_STATE_DOUT',
  'COM_IO_MAINBOARD_INVALID_FORMAT_CONFIG_ALL',
  'COM_IO_MAINBOARD_INVALID_FORMAT_CONFIG_DIN',
  'COM_IO_MAINBOARD_INVALID_FORMAT_CONFIG_DOUT',
  'COM_IO_MAINBOARD_INVALID_FORMAT_CONFIG_SIO',
  'COM_IO_MAINBOARD_INVALID_FORMAT_STATE_ALL',
  'COM_IO_MAINBOARD_INVALID_FORMAT_STATE_DOUT',
  'COM_IO_MAINBOARD_IVALID_IO_TYPE_STATE_SIO',
  'COM_IO_MAINBOARD_TYPE_CONFIG_SIO',
  'COM_IO_USERIO_INVALID_DATA_CONFIG_DIN',
  'COM_IO_USERIO_INVALID_DATA_CONFIG_DOUT',
  'COM_IO_USERIO_INVALID_FORMAT_ADC',
  'COM_IO_USERIO_INVALID_FORMAT_CONFIG_ALL',
  'COM_IO_USERIO_INVALID_FORMAT_CONFIG_IO_OVF',
  'COM_IO_USERIO_INVALID_FORMAT_CONFIG_TYPES',
  'COM_IO_USERIO_INVALID_FORMAT_DIN',
  'COM_IO_USERIO_INVALID_FORMAT_DOUT',
  'COM_IO_USERIO_INVALID_FORMAT_LENGTH',
  'COM_IO_USERIO_INVALID_FORMAT_IO_NUMBER',
  'COM_IO_USERIO_INVALID_FORMAT_IO_OVF',
  'COM_IO_USERIO_IVALID_IO_SIO_TYPE',
  'COM_IO_USERIO_IVALID_SIO_TYPE_STATE',
  'COM_IO_USERIO_IVALID_IO_TYPE_CONFIG_DIN',
  'COM_IO_USERIO_IVALID_IO_TYPE_CONFIG_DOUT',
  'COM_IO_USERIO_IVALID_IO_TYPE_STATE_DOUT',
  'COM_KL1_KL2',
  'COM_KL1_KL2_MISSING_HEARTBEAT',
  'COM_KL1_KL2_MISSING_GPIO',
  'COM_KL1_KL2_NOT_ACTIVE',
  'COM_KL1_KL2_PACKET_LOST',
  'COM_KL1_KL2_PACKET_SIZE_MISMATCH',
  'COM_KL1_KL2_PROTOCOL_RX_POINTER_OVFL',
  'COM_KL1_KL2_PROTOCOL_TX_POINTER_OVFL',
  'COM_KL1_KL2_REG_BUFFER_FULL',
  'COM_KL1_KL2_REQUEST_SAFETY_CONFIG',
  'COM_KL1_KL2_TX_PACKET_TOO_LONG',
  'COM_KL1_KL2_UNKNOWN_ACK_PACKET_RECEIVED',
  'COM_KL1_KL2_UNKNOWN_COMMAND',
  'COM_KL1_KL7',
  'COM_KL1_KL7_ERROR_BUFF_OVF',
  'COM_KL1_KL7_FUNC_FLAG_UNKOWN',
  'COM_KL1_KL7_HORSTFX_OFFLINE',
  'COM_KL1_KL7_MISSING_HEARTBEAT',
  'COM_KL1_KL7_PACKET_LOST',
  'COM_KL1_KL7_PACKET_SIZE_MISMATCH',
  'COM_KL1_KL7_PARAMETER_UNKNOWN',
  'COM_KL1_KL7_PROTOCOL_RX_POINTER_OVFL',
  'COM_KL1_KL7_PROTOCOL_TX_POINTER_OVFL',
  'COM_KL1_KL7_REG_BUFFER_FULL',
  'COM_KL1_KL7_TX_PACKET_TOO_LONG',
  'COM_KL1_KL7_UNKOWN_ACK_PACKET_RECEIVED',
  'COM_PACKET_LOST_KL1_KL2',
  'COM_PACKET_LOST_KL1_KL7',
  'COM_PROTOCOL',
  'CONTROLLER_CROSS_COMPARISSON_KL1_KL2',
  'CROSS_COMP_BUS_DRIVER',
  'CROSS_COMP_ENCODER',
  'CROSS_COMP_FLAG_FUNC_BUS_INITIALIZATION',
  'CROSS_COMP_FUNC_FLAG_BRAKE',
  'CROSS_COMP_FUNC_FLAG_ROBOT_MODE',
  'CROSS_COMP_FUNC_FLAG_ROBOT_SERVICE_MODE',
  'CROSS_COMP_FUNC_FLAG_STANDSTILL_MONITORING',
  'CROSS_COMP_FUNC_FLAG_TCP_VEL_LIMIT_EXCEEDED',
  'CROSS_COMP_JOINTS_ON',
  'CROSS_COMP_POWER_24V_VOLTAGE',
  'CROSS_COMP_POWER_48V_CURRENT',
  'CROSS_COMP_POWER_48V_VOLTAGE',
  'CROSS_COMP_ROBOT_MODEL',
  'CROSS_COMP_SAFETY_CONFIG',
  'CROSS_COMP_SAFETY_INPUT',
  'CROSS_COMP_SAFETY_IN_FUNC_INPUT',
  'CROSS_COMP_SAFETY_IN_FUNC_TRIGGERED',
  'CROSS_COMP_SAFETY_OUT_FUNC_VALUE',
  'CROSS_COMP_SAFETY_OUTPUT',
  'CURRENT',
  'DIGITAL_IO_OVF_GET_INPUT_FILTER',
  'DIGITAL_IO_OVF_GET_OUTPUT_MODE',
  'DIGITAL_IO_OVF_CONFIG_INPUT_FILTER',
  'DIGITAL_IO_OVF_CONFIG_OUTPUT_MODE',
  'DIGITAL_IO_OVF_GET_INPUT',
  'DIGITAL_IO_OVF_GET_INPUT_FILTER',
  'DIGITAL_IO_OVF_GET_OUTPUT',
  'DIGITAL_IO_OVF_SET_OUTPUT',
  'EMERGENCY_INPUT_MISMATCH',
  'EMERGENCY_OUTPUT_DIAG',
  'EMERGENCY_OUTPUT_KS4',
  'EMERGENCY_STOP',
  'EMERGENCY_STOP_TRIGGERED',
  'ENABLING_SWITCH_INPUT_MISMATCH',
  'ENCODER',
  'ENCODER_CRC',
  'ENCODER_DISCONNECT',
  'ENCODER_ID',
  'ENCODER_OFFSET_INVALID',
  'ENCODER_TIMEOUT',
  'ENCODER_1',
  'ENCODER_2',
  'ENCODER_3',
  'ENCODER_4',
  'ENCODER_5',
  'ENCODER_6',
  'ERROR_BUFFER_FULL',
  'ERROR_ID',
  'FLASH_READ_FAILED',
  'FLASH_WRITE_FAILED',
  'GEAR_LOOKUP_OUT_OF_BOUNDS',
  'HW_EEPROM_WRITE_FAILED',
  'HW_MAX14912_OV',
  'HW_MAX14912_READBACK_OUT',
  'HW_MAX14912_READBACK_PP',
  'HW_MAX14912_THERMAL',
  'HW_MAX14912_VDD_UVLO',
  'HW_MAX14912_VDD_WARN',
  'HW_MAX14912_5V_UVLO',
  'HW_MAX14912_8CKMULT',
  'HW_MAX31911_CRC',
  'HW_SPI_BUSY',
  'HW_SPI_CALLBACK_RXBUF',
  'IO_INVALID_ROBOT_MODE',
  'IO_OSSD_FAILED',
  'IO_OVF_CONFIG_FILTER',
  'IO_OVF_CONFIG_INPUT_OSSD',
  'IO_OVF_CONFIG_OUTPUT_MODE',
  'IO_OVF_CONFIG_OUTPUT_OSSD',
  'IO_OVF_GET_IN',
  'IO_OVF_GET_OUT',
  'IO_OVF_SET_IN_RAW',
  'IO_OVF_SET_OUT',
  'KL2',
  'MOTOR_CONTROLLER_1',
  'MOTOR_CONTROLLER_1_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_1_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_1_I2C',
  'MOTOR_CONTROLLER_1_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_1_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_1_TIMINGS',
  'MOTOR_CONTROLLER_1_TMC_RESET',
  'MOTOR_CONTROLLER_1_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_1_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_1_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_2',
  'MOTOR_CONTROLLER_2_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_2_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_2_I2C',
  'MOTOR_CONTROLLER_2_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_2_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_2_TIMINGS',
  'MOTOR_CONTROLLER_2_TMC_RESET',
  'MOTOR_CONTROLLER_2_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_2_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_2_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_3',
  'MOTOR_CONTROLLER_3_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_3_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_3_I2C',
  'MOTOR_CONTROLLER_3_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_3_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_3_TIMINGS',
  'MOTOR_CONTROLLER_3_TMC_RESET',
  'MOTOR_CONTROLLER_3_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_3_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_3_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_4',
  'MOTOR_CONTROLLER_4_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_4_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_4_I2C',
  'MOTOR_CONTROLLER_4_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_4_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_4_TIMINGS',
  'MOTOR_CONTROLLER_4_TMC_RESET',
  'MOTOR_CONTROLLER_4_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_4_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_4_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_5',
  'MOTOR_CONTROLLER_5_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_5_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_5_I2C',
  'MOTOR_CONTROLLER_5_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_5_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_5_TIMINGS',
  'MOTOR_CONTROLLER_5_TMC_RESET',
  'MOTOR_CONTROLLER_5_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_5_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_5_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_6',
  'MOTOR_CONTROLLER_6_ACCELERATION_EXCEEDED',
  'MOTOR_CONTROLLER_6_COMM_HEARTBEAT',
  'MOTOR_CONTROLLER_6_I2C',
  'MOTOR_CONTROLLER_6_MOTOR_CONTROLLER_SPI',
  'MOTOR_CONTROLLER_6_ROGUE_X_ACTUAL',
  'MOTOR_CONTROLLER_6_TIMINGS',
  'MOTOR_CONTROLLER_6_TMC_RESET',
  'MOTOR_CONTROLLER_6_VOLTAGE_BRAKE',
  'MOTOR_CONTROLLER_6_VOLTAGE_48V_LEVEL',
  'MOTOR_CONTROLLER_6_WRONG_FUNC_PARAMETER',
  'MOTOR_CONTROLLER_LOST_STEPS',
  'MOTOR_CONTROLLER_OVERTEMPERATURE',
  'MOTOR_CONTROLLER_SPI',
  'POWER_24V_IO_NOT_GOOD',
  'POWER_24V_VOLTAGE',
  'POWER_48V_NOT_INTERRUPTIBLE',
  'POWER_48V_VOLTAGE',
  'ROBOT_COMPUTATION_TIME_EXCEEDED',
  'ROBOT_EEPROM_CRC_READ_POWER_OFF_ANGLES',
  'ROBOT_GEAR_LOOKUP_OVFL',
  'ROBOT_JOINT_OVERLOAD',
  'ROBOT_JOINTS_ON',
  'ROBOT_MODE_SWITCH',
  'ROBOT_MODEL_ENCODER',
  'ROBOT_MODEL_KL1_KL2_MISMATCH',
  'ROBOT_MODEL_KL1_KL7_MISMATCH',
  'ROBOT_MODEL_MISMATCH',
  'ROBOT_MODEL_NOT_FOUND',
  'ROBOT_SERVICE_MODE',
  'SAFETY_BRAKING_TIME_EXCEEDED',
  'SAFETY_INPUT_MISMATCH',
  'SAFETY_IO_APPLY_CONFIG_EMPTY',
  'SAFETY_IO_CHECK_CONFIG_CRC',
  'SAFETY_IO_CHECK_CONFIG_INPUT_COUNT',
  'SAFETY_IO_CHECK_CONFIG_INPUT_FILTER',
  'SAFETY_IO_CHECK_CONFIG_INPUT_FUNC',
  'SAFETY_IO_CHECK_CONFIG_INPUT_OSSD',
  'SAFETY_IO_CHECK_CONFIG_OUTPUT_COUNT',
  'SAFETY_IO_CHECK_CONFIG_OUTPUT_EXT_READBACK',
  'SAFETY_IO_CHECK_CONFIG_OUTPUT_FUNC',
  'SAFETY_IO_CHECK_CONFIG_OUTPUT_OSSD',
  'SAFETY_IO_CHECK_CONFIG_TIMESTAMP',
  'SAFETY_IO_CHECK_CONFIG_VERSION',
  'SAFETY_IO_CONFIG_INPUT_OSSD',
  'SAFETY_IO_CONFIG_OUTPUT_OSSD',
  'SAFETY_IO_CONFIG_REQUIRES_UPDATE',
  'SAFETY_IO_EXT_READBACK_A',
  'SAFETY_IO_EXT_READBACK_B',
  'SAFETY_IO_OSSD_FAILED_INPUT',
  'SAFETY_IO_OSSD_FAILED_OUTPUT',
  'SAFETY_IO_OVF_CALL_SAFETY_HANDLER',
  'SAFETY_IO_OVF_EXT_READBACK',
  'SAFETY_IO_OVF_INPUTS_FILTER',
  'SAFETY_IO_OVF_INPUTS_FILTER_IO',
  'SAFETY_IO_OVF_INPUTS_OSSD',
  'SAFETY_IO_OVF_OUTPUTS_OSSD',
  'SAFETY_IO_OVF_GET_IN',
  'SAFETY_IO_OVF_GET_OUT',
  'SAFETY_IO_OVF_READBACK',
  'SAFETY_IO_OVF_SET_OUT',
  'SAFETY_IO_OVF_SET_OUT_CONFIG',
  'SAFETY_IO_READ_FROM_MEMORY',
  'SAFETY_IO_READBACK_CH_A',
  'SAFETY_IO_READBACK_CH_B',
  'SAFETY_IO_SET_SAFETY_CONFIG',
  'SAFETY_IO_WRITE_TO_MEMORY',
  'SAFETY_MAX_SYSTEM_VELOCITY_EXCEEDED',
  'SAFETY_OUTPUT_DIAG',
  'SAFETY_REDUCED_VELOCITY_EXCEEDED',
  'SAFETY_STANDSTILL_VIOLATION',
  'SAFETY_STOP',
  'SAFETY_STOP_TRIGGERED',
  'SAFETY_T1_VELOCITY_EXCEEDED',
  'SAFETY_WATCHDOG_KL4_TRIGGERED',
  'STANDSTILL_VIOLATION',
  'SWITCH_48V_KS2',
  'TCP_VEL_LIMIT_EXCEEDED',
  'TMC4361_BUF_OVERRUN',
  'VOLTAGE_BRAKE_SE3',
  'VOLTAGE_24_LEVEL_SE4',
  'VOLTAGE_48_CURRENT_SE2',
  'VOLTAGE_48V_LEVEL_SE1',
  'WATCHDOG_KL4_TRIGGERED',
  'WRONG_FUNC_PARAMETER',
]
