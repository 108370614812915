import { groupsApi } from '@/data'
import { GroupLicense } from '@/interfaces'
import { Ref } from 'vue'
import { toGroupLicense } from './_toGroupLicense'

export const createLoadLicenses = (licensesRef: Ref<Map<string, GroupLicense[]>>) => {
  return async (groupId: string, revalidate: boolean = false) => {
    try {
      if (!revalidate && licensesRef.value.has(groupId)) {
        return licensesRef.value.get(groupId)!
      }
      const response = await groupsApi.getGroupLicenses({
        id: groupId,
      })

      const licenses = response.map(toGroupLicense(groupId))
      licensesRef.value.set(groupId, licenses)
      return licenses
    } catch (error) {
      return []
    }
  }
}
