import { systemApi } from '@/data'
import { RobotSystem } from '@/interfaces'
import { convertFromMetersToKm } from '@/utils/helpers'
import { Ref } from 'vue'

export async function loadStatistics(system: Ref<RobotSystem>): Promise<void> {
  const res = await systemApi.getSummaryStatistics({ id: system.value.id })
  // In Bewegung (h)
  system.value.statistics.summary.baseHoursOfMovement = res.baseHoursOfMovement ? res.baseHoursOfMovement : 0
  // Roboter (h)
  system.value.statistics.summary.hfxHoursOfOperation = res.hfxHoursOfOperation ? res.hfxHoursOfOperation : 0
  // Strecke TCP (in meters)
  system.value.statistics.summary.tcpMovementSum = res.tcpMovementSum ? convertFromMetersToKm(res.tcpMovementSum) : 0
  // horstFX Systemstars
  system.value.statistics.summary.hfxLaunches = res.hfxLaunches ? res.hfxLaunches : 0
  // Programmstarts
  system.value.statistics.summary.hfxProgramLaunches = res.hfxProgramLaunches ? res.hfxProgramLaunches : 0
  // Fehler durch Achsüberlastungen
  system.value.statistics.summary.jointOverload = res.jointOverload ? res.jointOverload : 0 //NOTE: sum those up in view
  // Letze Aktualisierung
  system.value.statistics.summary.telemetryUpdateTime = res.telemetryUpdateTime
  //NOTE: index 0 is the total tcp movement indices 1-6 are the individual axis movements
  // Belastungen
  system.value.statistics.summary.axisMovement = res.movementAxisSums
    ? res.movementAxisSums.slice(1)
    : [0, 0, 0, 0, 0, 0, 0]
}
