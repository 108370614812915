import { issueApi } from '@/data'
import { RobotSystem, ServiceIssue } from '@/interfaces'
import { Ref } from 'vue'
import { injectSystemRefs, useSystemStore } from '@/stores/system/systemStore'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'
import { apiBusyState } from '@/data'
import { createServiceIssueFromApiModel } from '@/data/serviceIssues'

const toast = useToast()
const { t } = i18n.global

export function createFetchServiceIssueById(serviceIssueRef: Ref<ServiceIssue | null>) {
  return async (issueIdOrKey: string) => {
    apiBusyState.selectedIssue = true
    try {
      const jiraIssue = await issueApi.getIssue({ issueIdOrKey })
      await useSystemStore().fetchSystemById(jiraIssue.fields?.robotSystemId!)
      const refRobotSystem: Ref<RobotSystem | null> = injectSystemRefs().selectedSystem
      const serviceIssue = createServiceIssueFromApiModel(jiraIssue, refRobotSystem.value!, issueIdOrKey)
      serviceIssueRef.value = serviceIssue
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
    }
    apiBusyState.selectedIssue = false
    return
  }
}
