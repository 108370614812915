import { ILocationOptions } from '@/interfaces'
import { German } from 'flatpickr/dist/l10n/de.js'

export const deLocationOptions = {
  language: 'de',
  languageTag: 'de-DE',
  languageTagUnderline: 'de_DE',
  flatPickrDateFormat: 'd.m.Y H:i',
  flatPickrDateFormatWithoutTime: 'd.m.Y',
  flatPickrLang: German,
  luxonDateFormat: 'dd.LL.yyyy HH:mm',
  luxonDateFormatShort: 'dd.LL.yy HH:mm',
  luxonDateFormatTimeOfDay: 'ccc dd.LL HH:mm',
  luxonDateFormatFull: 'dd.LL.yyyy HH:mm:ss',
  luxonDateFormatExtended: 'dd.LL.yy HH:mm:ss.SSS',
  luxonFileDateFormat: 'dd-LL-yyyy',
  luxonDateFormatWithoutTime: 'dd.LL.yyyy',
  time24hr: true,
} as ILocationOptions
