import { hfxVersionAPI } from '@/data'
import { HfxVersion } from '@/interfaces'
import { HfxVersionDto } from 'hcosmos-api-data'
import { DateTime } from 'luxon'
import { Ref } from 'vue'

async function loadHfxVersions(versionRef: Ref<HfxVersion[] | null>) {
  if (versionRef.value === null) {
    const versions = await hfxVersionAPI.getVersions1()
    versionRef.value = [...versions].map(map)
  }

  return versionRef.value
}

export { loadHfxVersions }

const map = (version: HfxVersionDto): HfxVersion => {
  return {
    languages: [...(version.languages || [])],
    version: version.version!,
    releaseDate: DateTime.fromJSDate(version.releaseDate!),
    current: version.current || false,
    languageInsensitiv: version.languageInsensitive || [...(version.languages || [])].length === 0,
  }
}
