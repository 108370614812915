import { groupsApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { useToast } from 'vue-toastification'

export const createDeleteGroup = (...sideEffects: ((id: string) => Promise<unknown>)[]) => {
  const toast = useToast()
  const { t, te } = i18n.global

  return async (groupId: string, ...subgroupsIds: string[]) => {
    try {
      await groupsApi.removeSubgroups({
        id: groupId,
        requestBody: subgroupsIds,
      })

      sideEffects.forEach((f) => f(groupId))

      return subgroupsIds.length
    } catch (e) {
      console.error(e)
      const responseError = e as ResponseError
      const messagePath = `messages.permissions.subgroups.actions.remove.error.${responseError.response.status}`
      toast.error(te(messagePath) ? t(messagePath) : t('errors.default'))

      return 0
    }
  }
}
