import { bundleSystem } from '@/data/systems'
import { RobotSystem } from '@/interfaces'

export const createBundleSystem = function () {
  return async (system: RobotSystem, panelSerial: string, controlSerial: string) =>
    bundleSystem(
      system,
      panelSerial,
      '361e158d-f913-49ce-8cf4-fc22af40b8a3', // TODO: once the new panel is ready this must be a dynamic
      controlSerial,
      '25487055-0cd6-4d57-935b-0228f0369f72',
    )
}
