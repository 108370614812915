import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e94a76de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = ["id", "aria-expanded", "aria-controls"]
const _hoisted_3 = { class: "h4" }
const _hoisted_4 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      id: `${_ctx.title}-accordion-header`,
      "aria-expanded": _ctx.isOpen,
      "aria-controls": `${_ctx.title}-accordion-body`,
      class: "clear button header text-normal",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["prevent"]))
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("i", {
        "aria-hidden": "true",
        class: _normalizeClass(["bi bi-chevron-up chevron-animation", { open: _ctx.isOpen }])
      }, null, 2)
    ], 8, _hoisted_2),
    _createVNode(_Transition, {
      name: "expand",
      onEnter: _ctx.enter,
      onAfterEnter: _ctx.afterEnter,
      onLeave: _ctx.leave,
      duration: { enter: 380, leave: 320 }
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "body",
          id: `${_ctx.title}-accordion-body`,
          "aria-labelledby": `${_ctx.title}-accordion-header`
        }, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ], 8, _hoisted_4), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 3
    }, 8, ["onEnter", "onAfterEnter", "onLeave"])
  ]))
}