
import { authenticationInformation } from '@/oidc/authentication'
import { ConcreteComponent, defineComponent, h, onMounted, Ref, ref, toRefs, watch } from 'vue'
import { AssetFetchError, getAssetPath, loadUrlTemplate } from './documentation'
const propsDefinition = {
  url: {
    type: String,
    required: true,
  },
  type: {
    type: String,
  },
  pageid: {
    type: String,
  },
}

export default defineComponent({
  props: propsDefinition,
  name: 'AssetLink',
  setup(props, context) {
    const template = ref('<div key="nah"> Bitte warten. Die Seite wird geladen</div>')
    const user = authenticationInformation().user
    const { url } = toRefs(props)

    const updateAsset = () => {
      if (!url?.value) {
        return
      }
      const assetUrl = getAssetPath(url.value.substring(8))
      if (props.type === 'img') {
        template.value = `<img src="${assetUrl}">`
        return
      } else if (props.type === 'style') {
        template.value = '<div/>'
        const id = url.value.substring(8, 44) + url.value.substring(44).replaceAll('/', '-').replaceAll('.', '-')
        if (!document.getElementById(id)) {
          console.log('Injecting ' + url.value)
          const link = document.createElement('link')
          link.setAttribute('rel', 'stylesheet')
          link.setAttribute('href', assetUrl)
          link.id = id
          document.head.appendChild(link)
        }
        return
      } else if (props.type === 'download') {
        template.value = `<a href="${assetUrl}"><slot></slot></a>`
        return
      }

      //html tablet inject as content
      else {
        loadUrlTemplate(url.value, user)
          .then(async (res) => {
            template.value = await res.text()
          })
          .catch((error: AssetFetchError) => {
            console.log(
              'could not fetch asset error code ' +
                error.errorCode +
                ' message:  ' +
                error.message +
                ' roles: ' +
                error.serverResponse?.message,
            )
            if (error.errorCode === 403) {
              template.value =
                `
              <div class= "grid-y login-container">
                <h2> Der vollständige Artikel ist für folgende Nutzergruppen sichtbar: ` +
                error.serverResponse?.message +
                `.</h2>
                <h3> Bitte loggen Sie sich ein wenn Sie bereits einen horstCOSMOS account besitzen. </h3>
                <button class="button primary login-button"> <a @click="downloadLink" class="login-button">Einloggen</a> </button>
                 <h3> Wenn sie noch keinen Account haben können Sie sich hier registrieren. </h3>
                <button class="button primary login-button"> <a class="login-button" @click="downloadLink">Registieren</a> </button>
              </div>
              `
            }
          })
      }
    }
    watch(url as Ref<string>, updateAsset, { immediate: true })

    watch(user, updateAsset)

    const render = () => {
      const component: ConcreteComponent = {
        template: template.value,
        props: propsDefinition,
        //here can be a setup function if required

        setup() {
          const user = authenticationInformation().user

          const downloadLink = () => {
            user?.login()
          }

          const initToc = onMounted(() => {
            console.log('I am ' + props.url)
            if (props.pageid) {
              console.log('TOC pageid: ' + props.pageid)
              let element = document.querySelector(`a[data-destpageid="${props.pageid}"]`)
              if (element) {
                console.log('Found toc element')
                element.classList.add('current')
                element = element.parentElement
                while (element) {
                  if (element.tagName === 'LI') {
                    element.classList.add('open')
                    element.classList.add('active')
                    element.classList.remove('collapsed')
                  }
                  element = element.parentElement
                }
              }
            }
          })

          return {
            initToc,
            downloadLink,
          }
        },

        methods: {
          confluenceExportToggleMenu(event: MouseEvent) {
            console.log('clicked Menu')

            const menuItem = (event.target as Element).parentElement

            if (!menuItem) {
              console.error('Menu parent not found')
              return
            }

            if (menuItem.classList.contains('collapsed')) {
              menuItem.classList.remove('collapsed')
              menuItem.classList.add('open')
            } else {
              menuItem.classList.remove('open')
              menuItem.classList.add('collapsed')
            }
          },
        },
      }

      return h(component, props, context.slots)
    }
    return render
  },
})
