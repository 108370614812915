import { AuthenticationInformation, oidcEntity } from '@/oidc'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  /**
   * Use for calls while initial loading
   * @returns promise
   */
  function whenAuthenticated() {
    return oidcEntity.isAuthenticated
      ? Promise.resolve()
      : new Promise((resolve) => {
          oidcEntity.addUserChangeHandler(resolve)
        })
  }
  function logout() {
    oidcEntity.logout()
  }

  const isAuthenticated = computed(() => oidcEntity.isAuthenticated)

  return {
    user: oidcEntity as AuthenticationInformation,
    whenAuthenticated,
    logout,
    isAuthenticated,
  }
})
