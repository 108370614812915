import { systemApi } from '@/data'
import { createRobotSystemFromApiModel } from '@/data/systems'
import { RobotSystem } from '@/interfaces'
import { Ref } from 'vue'
import { loadContracts } from './loadContracts'
import { loadStatistics } from './loadStatistics'

export function createFetchSystemBySerial(systemRef: Ref<null | RobotSystem>) {
  return async (serial: string) => {
    const robot = createRobotSystemFromApiModel(await systemApi.bySerialNumberDetailed({ serial }))
    systemRef.value = robot
    loadContracts(systemRef as Ref<RobotSystem>)
    loadStatistics(systemRef as Ref<RobotSystem>)
  }
}
