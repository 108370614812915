import { organizationsAPI } from '@/data'

import { Member, Organization } from '@/interfaces'

import { Ref } from 'vue'
import { toMember } from './members/_toMembers'

export const createGetRecommendations = (orgRef: Ref<undefined | Organization>, recomandationsRef: Ref<Member[]>) => {
  return async () => {
    const possibleUsers = await organizationsAPI.listInvites({
      id: orgRef.value?.id!,
    })
    recomandationsRef.value = possibleUsers.map(toMember)
    return recomandationsRef.value
  }
}
