import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n' // https://vue-i18n.intlify.dev/

const i18n = createI18n({
  locale: 'de',
  allowComposition: true,
  fallbackLocale: 'de',
  messages: loadLocalMessages(),
})

export function loadLocalMessages() {
  const locales = require.context('.', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: { [key: string]: LocaleMessages<VueMessageType> } = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default i18n
