import { groupsApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError, UserGroupPermissionRolesEnum } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'

export const createSetMemberRole = (...sideEffects: ((id: string) => Promise<unknown>)[]) => {
  const toast = useToast()
  const { t, te } = i18n.global

  return async (groupid: string, userId: string, ...roles: UserGroupPermissionRolesEnum[]) => {
    try {
      await groupsApi.setUserRoles({
        id: groupid,
        userGroupPermission: {
          user: userId,
          roles: new Set(roles),
        },
      })

      sideEffects.forEach((f) => f(groupid))
      return true
    } catch (e) {
      console.error(e)

      const status = (e as ResponseError).response.status
      const path = `messages.permissions.members.actions.addRole.errors.${status}`
      toast.error(te(path) ? t(path) : t('errors.default'))

      return false
    }
  }
}
