import { systemApi } from '@/data'
import { RobotSystem } from '@/interfaces'
import { NotificationTypeEnum } from 'hcosmos-api-data'
import { useToast } from 'vue-toastification'

const toast = useToast()

/**
 * Method to send notification to the sales representative that a request of Robot Upgrade or Software Upgrade has been made.
 + If the request is Robot Upgrade: NotificationTypeEnum -> UpgradeContract
 + If the request is Software Upgrade: NotificationTypeEnum -> BuyNewPackage
 * @param system 
 * @param type 
 * @param message 
 * @param startTime 
 * @param endTime 
 * @returns 
 */
export function createSendSystemNotification() {
  return async (system: RobotSystem, type: NotificationTypeEnum, message: string, startTime: Date, endTime: Date) => {
    try {
      await systemApi.sendNotification({
        id: system.id,
        notification: {
          type,
          message,
          startTime,
          endTime,
        },
      })
      return
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
        throw error
      } else {
        console.error(error)
        toast.error('There was an error sending the notification. Server Error')
        throw Error(`There was an error sending the notification. Server Error ${error}`)
      }
    }
  }
}
