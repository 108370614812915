
import { uiOptions } from '@/config'
import { computed, defineComponent, PropType, watch, watchEffect } from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, context) {
    uiOptions().isModalOpen[props.id] = false

    const outSideClick = (e: Event) => {
      const target = e.target as HTMLElement
      if (target.className === 'modal') {
        uiOptions().toggleModal(props.id)
      }
    }

    //NOTE - Possible sizes: medium-modal, and big-modal; default small
    const modalSize = computed(() => (context.attrs.size ? context.attrs.size : ''))

    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        uiOptions().isModalOpen[props.id] = false
        document.removeEventListener('keydown', handler)
      }
    }

    const handleEsc = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        event.preventDefault()
        uiOptions().toggleModal(props.id)
      }
    }
    watchEffect(() => {
      if (uiOptions().isModalOpen[props.id]) {
        document.addEventListener('keyup', handleEsc)
      } else {
        document.removeEventListener('keyup', handleEsc)
      }
    })

    const modal = computed(() => uiOptions().isModalOpen[props.id])
    watch(modal, (newValue) => {
      if (newValue) {
        document.addEventListener('keydown', handler)
      }
    })

    return { modalSize, outSideClick, uiOptions }
  },
})
