import { Organization } from '@/interfaces'
import { stringOrDefault, dataOrDefault } from '@/utils/helpers'
import { Organization as HOrganization } from 'hcosmos-api-data'

export function toOrganization(fromBe: HOrganization): Organization {
  return {
    id: stringOrDefault(fromBe.id),
    name: stringOrDefault(fromBe.name),
    attributes: dataOrDefault(fromBe.attributes, {}),
  }
}
