import { authorityHost } from '@/utils/endpoints'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

const userManager = new UserManager({
  authority: authorityHost() + '/auth/realms/horstcosmos/',
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
  response_type: 'code',
  redirect_uri: window.location.host,
  scope: 'openid profile email',
  automaticSilentRenew: false,
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
})

userManager.clearStaleState()
//userManager.stopSilentRenew()

if (window.location.pathname === '/oidc-callback.html') {
  userManager.signinRedirectCallback().then((user) => {
    //userManager.stopSilentRenew()
    window.location.href = user.state as string
  })
} else if (window.location.pathname === '/oidc-silent-callback.html') {
  userManager.signinSilentCallback()
}
