import { groupsApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { useToast } from 'vue-toastification'

export const createAttachLicenseToGroup = (...sideEffects: (() => Promise<unknown>)[]) => {
  const toast = useToast()
  const { t, te } = i18n.global

  return async (groupId: string, license: string) => {
    try {
      await groupsApi.addGroupLicense({
        id: groupId,
        requestBody: [license],
      })

      sideEffects.forEach((f) => f())
      return true
    } catch (e) {
      console.error(e)
      const status = (e as ResponseError).response.status
      const path = `messages.permissions.licenses.actions.move.errors.${status}`
      toast.error(te(path) ? t(path) : t('errors.default'))
      return false
    }
  }
}
