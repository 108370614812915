import { groupsApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'

export const createRemoveMember = (...sideEffects: ((groupid: string) => Promise<unknown>)[]) => {
  const toast = useToast()
  const { t, te } = i18n.global

  return async (groupid: string, memberIds: string[]) => {
    try {
      await groupsApi.removeGroupMembers({
        id: groupid,
        requestBody: memberIds,
      })

      sideEffects.forEach((f) => f(groupid))
      return memberIds.length
    } catch (e) {
      console.error(e)
      const status = (e as ResponseError).response.status
      const path = `messages.permissions.members.actions.remove.errors.${status}`
      toast.error(te(path) ? t(path) : t('errors.default'))

      return 0
    }
  }
}
