import { HorstFXVersions } from '@/types'

export const horstFXVersions: HorstFXVersions = [
  '2022.07_hotfix4',
  '2022.07_hotfix3',
  '2022.07_hotfix2',
  '2022.07_hotfix1',
  '2022.07',
  '2022.04_hotfix6',
  '2022.04_hotfix5',
  '2022.04_hotfix4',
  '2022.04_hotfix3',
  '2022.04_hotfix2',
  '2022.04_hotfix1',
  '2022.04',
  '2022.01_hotifx1',
  '2022.01_hotfix3',
  '2022.01',
  '2021.10_hotfix7',
  '2021.10_hotfix6',
  '2021.10_hotfix5',
  '2021.10_hotfix4',
  '2021.10_hotfix3',
  '2021.10_hotfix2',
  '2021.10_hotfix1',
  '2021.10',
  '2021.07_hotfix3',
  '2021.07_hotfix2',
  '2021.07_hotfix1',
  '2021.07',
  '2021.04_hotfix1',
  '2021.04-H1400S1',
  '2021.04',
  '2021.03',
  '2021.01',
  '2020.10-hotfix3',
  '2020.10-hotfix2',
  '2020.10-hotfix1',
  '2020.10',
  '2020.06-H600',
  '2020.02-bus-hotfix1',
]
