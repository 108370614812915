/* tslint:disable */
/* eslint-disable */
/**
 * horstFXWebApi
 * Die API f�r horstFX WEB
 *
 * The version of the OpenAPI document: 1.0
 * Contact: alexander.jaeger@fruitcore.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { accessToken } from '@/data'
import * as runtime from '../runtime'

export interface ClaimInstanceRequest {
  id: string
}

export interface Delete1Request {
  id: string
}

export interface ExtendLifetimeRequest {
  id: string
}

export interface GetInstanceTokenRequest {
  id: string
}

/**
 * InstanceControllerApi - interface
 *
 * @export
 * @interface InstanceControllerApiInterface
 */
export interface InstanceControllerApiInterface {
  /**
   * Claims an instance for the user
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstanceControllerApiInterface
   */
  claimInstanceRaw(requestParameters: ClaimInstanceRequest): Promise<runtime.ApiResponse<void>>

  /**
   * Claims an instance for the user
   */
  claimInstance(requestParameters: ClaimInstanceRequest): Promise<void>

  /**
   * Deletes and stops a horstFXWeb instance
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstanceControllerApiInterface
   */
  delete1Raw(requestParameters: Delete1Request): Promise<runtime.ApiResponse<string>>

  /**
   * Deletes and stops a horstFXWeb instance
   */
  delete1(requestParameters: Delete1Request): Promise<string>

  /**
   * Extends the expiration date of an instance by 1 minute
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstanceControllerApiInterface
   */
  extendLifetimeRaw(requestParameters: ExtendLifetimeRequest): Promise<runtime.ApiResponse<void>>

  /**
   * Extends the expiration date of an instance by 1 minute
   */
  extendLifetime(requestParameters: ExtendLifetimeRequest): Promise<void>

  /**
   * Retrieves a connection token for a horstFX instance
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstanceControllerApiInterface
   */
  getInstanceTokenRaw(requestParameters: GetInstanceTokenRequest): Promise<runtime.ApiResponse<string>>

  /**
   * Retrieves a connection token for a horstFX instance
   */
  getInstanceToken(requestParameters: GetInstanceTokenRequest): Promise<string>
}

/**
 *
 */
export class InstanceControllerApi extends runtime.BaseAPI implements InstanceControllerApiInterface {
  /**
   * Claims an instance for the user
   */
  async claimInstanceRaw(requestParameters: ClaimInstanceRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling claimInstance.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token

      if (tokenString) {
        headerParameters['Authorization'] = `${tokenString}`
      }
    }
    const response = await this.request({
      path: `/api/v1/instance/{id}/claim`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Claims an instance for the user
   */
  async claimInstance(requestParameters: ClaimInstanceRequest): Promise<void> {
    await this.claimInstanceRaw(requestParameters)
  }

  /**
   * Deletes and stops a horstFXWeb instance
   */
  async delete1Raw(requestParameters: Delete1Request): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling delete1.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/api/v1/instance/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Deletes and stops a horstFXWeb instance
   */
  async delete1(requestParameters: Delete1Request): Promise<string> {
    const response = await this.delete1Raw(requestParameters)
    return await response.value()
  }

  /**
   * Extends the expiration date of an instance by 1 minute
   */
  async extendLifetimeRaw(requestParameters: ExtendLifetimeRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling extendLifetime.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/api/v1/instance/{id}/extend`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Extends the expiration date of an instance by 1 minute
   */
  async extendLifetime(requestParameters: ExtendLifetimeRequest): Promise<void> {
    await this.extendLifetimeRaw(requestParameters)
  }

  /**
   * @deprecated TODO: Should be replaced with the api
   * Retrieves a connection token for a horstFX instance
   */
  async getInstanceTokenRaw(requestParameters: GetInstanceTokenRequest): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getInstanceToken.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {
      Authorization: accessToken(),
    }

    const response = await this.request({
      path: `/api/v1/instance/{id}/token`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Retrieves a connection token for a horstFX instance
   */
  async getInstanceToken(requestParameters: GetInstanceTokenRequest): Promise<string> {
    const response = await this.getInstanceTokenRaw(requestParameters)
    return await response.value()
  }
}
