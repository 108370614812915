import { userApi } from '@/data'
import { NotificationTypeEnum } from 'hcosmos-api-data'

function createSendLicenseNotification() {
  return async (message: string, startTime: Date, endTime: Date) => {
    return await userApi.sendNotification1({
      notification: {
        type: NotificationTypeEnum.BuyNewLicense,
        message,
        startTime,
        endTime,
      },
    })
  }
}

export { createSendLicenseNotification }
