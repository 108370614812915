
import { DisplayColumn } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ExternalLink',
  props: {
    classes: {
      type: Array as PropType<DisplayColumn['class']>,
      required: true,
    },
  },
  setup() {
    const fruitcoreShopLink = process.env.VUE_APP_FRUITCORE_ROBOTICS_SHOP
    const navigateExternal = () => {
      window.open(fruitcoreShopLink, '_blank')
    }

    return {
      navigateExternal,
    }
  },
})
