import { AssignToSystemTypeEnum } from 'hcosmos-api-data'
import { componentApi } from '..'

/**
 *
 * @param id
 * @param systemId
 * @param type ROBOTARM | HCONTROL | HPANEL
 */
export async function assignComponentToSystem(
  id: string,
  systemId: string,
  type: AssignToSystemTypeEnum,
): Promise<void> {
  try {
    await componentApi.assignToSystem({
      body: systemId,
      id,
      type,
    })
  } catch (e) {
    throw new Error(
      `${type} "${id}" konnte nicht zum System "${systemId}" hinzugefügt werden: ${(e as Response).status}${
        (e as Response).statusText
      }`,
    )
  }
}
