import { systemApi } from '@/data'
import { RobotSystem } from '@/interfaces'
import { AttributeUpdateTypeEnum, UpdateAttribute1Request } from 'hcosmos-api-data'

/**
 *
 * @param id
 * @param type "SERIAL_NUMBER" | "MODEL" | "JIRA_ISSUE" | "NICKNAME" | "USER_DESCRIPTION"
 * @param value
 */
export function createUpdateUserSystemAttribute() {
  return async (id: RobotSystem['id'], type: AttributeUpdateTypeEnum, value: string | undefined) => {
    const requestParameters: UpdateAttribute1Request = {
      id,
      attributeUpdate: {
        type,
        value,
      },
    }
    await systemApi.updateAttribute1(requestParameters)
  }
}
