import { internalApi } from '@/data'
import { Version } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { SimpleHfxVersion } from '@/interfaces'
import { DateTime } from 'luxon'

async function fetchFullVersionList(versionsListRef: Ref<SimpleHfxVersion[] | null>) {
  if (versionsListRef.value === null) {
    const versionList = await internalApi.gethorstFXVersions()
    versionsListRef.value = [...versionList].map(map)
  }
  return versionsListRef.value
}

export { fetchFullVersionList }

const map = (version: Version): SimpleHfxVersion => {
  return {
    version: version.name!,
    releaseDate: DateTime.fromJSDate(version.releasDate!),
  }
}
