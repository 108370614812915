import { groupsApi } from '@/data'
import { stringOrDefault } from '@/utils/helpers'
import { Subgroup } from '@/interfaces'
import { dataOrDefault } from '@/utils/helpers'
import { GroupListEntry } from 'hcosmos-api-data'
import { Ref } from 'vue'

export const createLoadSubgroups = (subgroupsMap: Ref<Map<string, Subgroup[]>>) => {
  return async (groupId: string, revalidate: boolean = false) => {
    if (!revalidate && subgroupsMap.value.has(groupId)) {
      return subgroupsMap.value.get(groupId)!
    }

    try {
      const subgroups = await groupsApi
        .getSubgroups({
          id: groupId,
        })
        .then((res) => res.map(toGroup))

      subgroupsMap.value.set(groupId, subgroups)

      return subgroups
    } catch (e) {
      subgroupsMap.value.set(groupId, [])
      console.error(e)
      return []
    }
  }
}

function toGroup(groupListEntry: GroupListEntry): Subgroup {
  return {
    id: stringOrDefault(groupListEntry.id),
    name: stringOrDefault(groupListEntry.name),
    attributes: dataOrDefault(groupListEntry.attributes, {}),
  }
}
