import { AuthenticationInformation } from '@/oidc'
import { inject } from '@vue/runtime-core'
import { useToast } from 'vue-toastification'

const toast = useToast()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function authenticationInformation() {
  const oidc: AuthenticationInformation = inject('oidc') as AuthenticationInformation

  if (!oidc) {
    toast.error('oidc plugin not found or not active')
    console.error('oidc plugin not found or not active')
    throw new String('oidcc plugin not found or not active')
  }

  return {
    user: oidc,
  }
}
