import { organizationsAPI } from '@/data'

import { Organization } from '@/interfaces'
import { Ref } from 'vue'
import { toOrganization } from './_toOrganization'

export function createLoadOrganizationsList(organizationsRef: Ref<Promise<Organization[]> | null>) {
  return async () => {
    if (organizationsRef.value !== null) {
      return organizationsRef.value as Promise<Organization[]>
    }
    const promise = organizationsAPI.list1().then((res) => res.map(toOrganization) as Organization[])
    organizationsRef.value = promise
    return promise
  }
}
