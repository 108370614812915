interface LookupGetter<T, R> {
  (key: T | undefined): R
  reverse: (value: Exclude<R, undefined>) => T | undefined
}

/**
 *
 * Example usage:
 *  const look = lookup({ 'A': 'a', 'B': 'b'})
 *  look('A') // 'a'
 *  look('B') // 'b'
 *  look('C') // undefined
 *  look.reverse('a') // 'A'
 *  look.reverse('b') // 'B'
 *
 *  const look2 = lookup({ 'A': 'a', 'B': 'b', default: 'Z'})
 *  look('C') // 'Z'
 *
 */
export function lookup<T extends string | number | symbol, R>(dict: Record<T, R>): LookupGetter<T, R | undefined>
export function lookup<T extends string | number | symbol, R>(
  dict: Record<T, R>,
  defaultValue?: R | ((value: T) => R),
): LookupGetter<T, R>
export function lookup<T extends string | number | symbol, R>(
  dict: Record<T, R>,
  defaultValue?: R | ((value: T) => R),
): LookupGetter<T, R | undefined> {
  const getter: LookupGetter<T, R | undefined> = function (key) {
    if (key && dict[key]) {
      return dict[key] as R
    }

    if (defaultValue) {
      return typeof defaultValue === 'function' ? ((defaultValue as Function)(key) as R) : (defaultValue as R)
    }
    return undefined
  }

  getter.reverse = function (value: R) {
    const foundEntry = Object.entries(dict).find(([, val]) => val === value)
    return foundEntry ? (foundEntry[0] as T) : undefined
  }

  return getter
}
