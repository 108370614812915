import { apiBusyState } from '@/data'
import { Ref } from 'vue'
import { TableJiraIssue } from 'hcosmos-api-service'
import { issueApi } from '@/data'
import { injectFleetRefs } from '@/stores'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'
import { createTableServiceIssueFromApiModel } from '@/data/serviceIssues'
import { TableServiceIssue } from '@/interfaces/TableServiceIssue'

const toast = useToast()
const { t } = i18n.global

function createFetchServiceIssues(ref: Ref<TableServiceIssue[]>) {
  return async (revalidate: boolean = false) => {
    if (!revalidate && ref.value.length > 0) {
      return ref.value
    }
    try {
      apiBusyState.issues = true
      const loadedApiUserServiceIssues = await loadApiUserServiceIssues()
      const arrayJiraIssueWithHeader = loadedApiUserServiceIssues.responseJiraIssue.flat()
      const serviceIssuesWithRobotSystems = arrayJiraIssueWithHeader.map((serviceIssue) => {
        const serviceIssueRobotSystem = loadedApiUserServiceIssues.userRobotSystems.filter(
          (robotSystem) => robotSystem.id === serviceIssue.fields?.robotSystemId,
        )[0]
        return createTableServiceIssueFromApiModel(serviceIssue, serviceIssueRobotSystem)
      })
      ref.value = serviceIssuesWithRobotSystems
      return serviceIssuesWithRobotSystems
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
      return ref.value
    } finally {
      apiBusyState.issues = false
    }
  }
}

const loadApiUserServiceIssues = async () => {
  const refRobotSystems = injectFleetRefs().systems
  let isLoaded = false
  const issues: TableJiraIssue[] = []
  let startAt = 0
  while (!isLoaded) {
    const result = await issueApi.listIssues({ size: 200, startAt })
    issues.push(...(result.results || []))
    startAt = result.pointer!
    isLoaded = result.total === result.pointer
  }

  return { responseJiraIssue: issues, userRobotSystems: refRobotSystems.value }
}

export { createFetchServiceIssues }
