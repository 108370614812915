import { CONTRACT_FEATURE_TYPE, EntityState } from '@/enums'
import { RobotSystem, Robot, Panel, Control, Contract, ContractFeature } from '@/interfaces'
import { RobotSystemEvent } from '@/interfaces'
import { RobotSystemComponentType, RobotSystemEventTypes } from '@/enums'
import { faker } from '@faker-js/faker'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import { generateServiceIssueDummies } from '../serviceIssues'
import { getRobotModelByName } from '@/data/models'
import { LicensePackageInformationContractTypeEnum } from 'hcosmos-api-data'

/**
 *
 * @param serialNumber
 * @returns RobotSystem
 */
export function systemDummy(serialNumber?: string): RobotSystem {
  const baseHours = Math.round(24 + Math.random() * 500)
  const hfxHours = Math.round(baseHours * 0.13 + Math.random() * 1000)
  const id = uuidv4()
  const serial = serialNumber ? serialNumber : 'RHA' + dummyFCSerial()

  return {
    id,
    serialNumber: serial,
    robot: robotDummy(),
    panel: panelDummy(),
    control: controlDummy(),
    history: faker.datatype
      .array(faker.datatype.number({ min: 2, max: 5 }))
      .map(() => robotSystemEventDummy()) as RobotSystemEvent[],
    statistics: {
      summary: {
        baseHoursOfMovement: baseHours,
        hfxHoursOfOperation: hfxHours,
        axisMovement: [
          Math.random() * 612_000, // Achse 1
          Math.random() * 360_000, // Achse 2
          Math.random() * 340_000, // Achse 3
          Math.random() * 350_000, // Achse 4
          Math.random() * 460_000, // Achse 5
          Math.random() * 580_000, // Achse 6
        ],
        hfxLaunches: Math.round(Math.random() * 10),
        hfxProgramLaunches: Math.round(Math.random() * 10),
        jointOverload: Math.round(Math.random() * 10),
        tcpMovementSum: Math.round(Math.random() * 265_000),
        movementAxisSums: [
          Math.random() * 10, // Achse 1
          Math.random() * 10, // Achse 2
          Math.random() * 10, // Achse 3
          Math.random() * 10, // Achse 4
          Math.random() * 10, // Achse 5
          Math.random() * 10, // Achse 6
        ],
        telemetryUpdateTime: faker.date.recent(30),
      },
    },
    attributes: {
      nickname: faker.company.catchPhraseAdjective(),
      userDescription: faker.lorem.paragraph(),
    },
    entityState: Math.random() > 0.1 ? EntityState.ACTIVE : EntityState.PRODUCTION,
    buildDates: {
      robotArm: DateTime.fromJSDate(faker.date.recent(19)),
    },
    customers: customersDummy(),
    services: generateServiceIssueDummies(serial, id),
    contracts: contractsDummy(),
  }
}

/**
 *
 * @returns Robot
 */
function robotDummy(): Robot {
  return {
    id: Math.round(Math.random() * 500).toString(),
    serialNumber: 'PHA' + dummyFCSerial(),
    model: Math.random() >= 0.5 ? getRobotModelByName('HORST 600') : getRobotModelByName('HORST 900'),
  }
}

/**
 *
 * @returns Panel
 */
function panelDummy(): Panel {
  return {
    id: Math.round(Math.random() * 500).toString(),
    serialNumber: 'PPA' + dummyFCSerial(),
  }
}

/**
 *
 * @returns Control
 */
function controlDummy(): Control {
  return {
    id: Math.round(Math.random() * 500).toString(),
    serialNumber: 'PCA' + dummyFCSerial(),
  }
}

/**
 *
 * @returns string
 */
function dummyFCSerial(): string {
  return Math.round(Math.random() * 10000)
    .toString()
    .padStart(6, '0')
}

/**
 *
 * @returns Customer[]
 */
function customersDummy(): RobotSystem['customers'] {
  const customers = []
  while (customers.length < Math.random() * (4 - 1)) {
    customers.push({
      id: uuidv4(),
      name: faker.company.name(),
    })
  }
  return customers
}

function contractsDummy(): Contract[] {
  const contracts: Array<Contract> = []
  while (contracts.length < Number(faker.random.numeric())) {
    contracts.push({
      title: faker.commerce.productName(),
      start: DateTime.fromJSDate(faker.date.recent(15)),
      end: DateTime.fromJSDate(faker.date.soon(5)),
      jiraIssue: faker.company.bsAdjective(),
      type: faker.helpers.objectValue(LicensePackageInformationContractTypeEnum),
      features: featuresDummy(),
    })
  }
  return contracts
}

function featuresDummy(): ContractFeature[] {
  const contractFeatures: ContractFeature[] = []
  while (contractFeatures.length < Number(faker.random.numeric())) {
    contractFeatures.push({
      type: faker.helpers.objectValue(CONTRACT_FEATURE_TYPE),
      featureStart: DateTime.fromJSDate(faker.date.recent(15)),
      featureEnd: DateTime.fromJSDate(faker.date.soon(5)),
    })
  }
  return contractFeatures
}

export function robotSystemEventDummy(): RobotSystemEvent {
  return {
    event: faker.helpers.arrayElement([
      RobotSystemEventTypes.CREATED,
      RobotSystemEventTypes.COMPONENT_ATTACHED,
      RobotSystemEventTypes.COMPONENT_REMOVED,
      RobotSystemEventTypes.PACKAGED,
      RobotSystemEventTypes.SHIPPED,
    ]),
    id: faker.datatype.number({ precision: 0 }),
    reference: faker.datatype.uuid(),
    timestamp: DateTime.fromJSDate(faker.date.past()),
    componentReference: faker.datatype.uuid(),
    componentType: faker.helpers.arrayElement([
      RobotSystemComponentType.ROBOTARM,
      RobotSystemComponentType.HCONTROL,
      RobotSystemComponentType.HPANEL,
    ]),
  }
}
