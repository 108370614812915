import { groupsApi } from '@/data'
import i18n from '@/locales/i18n'
import { ResponseError } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'

export const createAddMembersToGroup = (...sideEffects: ((groupId: string) => Promise<unknown>)[]) => {
  const toast = useToast()
  const { t, te } = i18n.global

  return async (groupId: string, members: string[]) => {
    try {
      await groupsApi.addGroupMembers({
        id: groupId,
        requestBody: members,
      })

      sideEffects.forEach((f) => f(groupId))
      return members.length
    } catch (e: unknown) {
      console.error(e)
      const status = (e as ResponseError).response.status
      const path = `messages.permissions.members.actions.addUser.errors.${status}`
      const message = te(path) ? t(path) : t('errors.default')
      toast.error(message)
      throw new Error(message, { cause: e as ResponseError })
    }
  }
}
