import { LicenseContractType, LicenseType } from '@/enums'
import { Group, GroupLicense, Member, Organization } from '@/interfaces'
import { faker } from '@faker-js/faker'
import { DateTime } from 'luxon'

/**
 * boolean(10) -> 9 of 10 calls return true
 * boolean(90) -> 1 of 10 calls return true
 *
 * @param falsePercentage {number} The percentage that need to be reached to return true
 * @returns {boolean}
 */
const boolean = (falsePercentage: number = 50) => faker.datatype.number(100) >= falsePercentage

const fakeArray = (min: number, max: number) =>
  faker.datatype.array(faker.datatype.number({ min, max })).map((never, i) => i)

export function dummyOrganizationList(amount: number = 5): Organization[] {
  return fakeArray(1, amount).map((i) => {
    return dummyOrganization()
  })
}

export function dummyOrganization(): Organization {
  return {
    id: faker.datatype.uuid(),
    name: faker.company.companyName(),
    attributes: {
      Kundennummer: [faker.datatype.uuid()],
    },
  }
}

export function dummyGroup(): Group {
  return {
    id: faker.datatype.uuid(),
    name: faker.commerce.department(),
    path: fakeArray(1, 3).map(faker.internet.domainWord).join('/'),
    serviceGroup: boolean(80),
    attributes: {
      Kundennummer: [faker.datatype.uuid()],
    },
    userLimit: undefined,
    userRoles: {
      GROUP_ADMIN: [],
      ORGANIZATION_ADMIN: [],
    },
  }
}

export function dummyGroups(): Group[] {
  return fakeArray(0, 6).map(dummyGroup)
}

export function dummySubGroups(): Group[] {
  return boolean(80) ? dummyGroups() : []
}

export function dummyMember(robot: boolean | undefined = undefined): Member {
  const isRobot = robot || faker.datatype.boolean()

  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()

  return {
    lastName,
    firstName,
    email: faker.internet.email(firstName, lastName, isRobot ? 'fruitcore.de' : undefined),
    id: faker.datatype.uuid(),
    isRobot: isRobot,
    isInvited: faker.datatype.boolean(),
  }
}

export function dummyMembers(): Member[] {
  return fakeArray(2, 10).map(() => dummyMember())
}

export function dummyLicenses(): GroupLicense[] {
  return fakeArray(0, 4).map(dummyLicense)
}

export function dummyLicense(): GroupLicense {
  return {
    jiraIssue: faker.fake('LICENSE-{{datatype.number}}'),
    comment: faker.lorem.sentence(),
    contractType: faker.datatype.boolean() ? LicenseContractType.SERVICE_CONTRACT : LicenseContractType.SOFTWARE,
    features: [],
    organization: faker.datatype.uuid(),
    owner: faker.datatype.uuid(),
    rented: [],
    timeEnd: DateTime.fromJSDate(faker.date.future()),
    timeStart: DateTime.fromJSDate(faker.date.past()),
    title: {
      en_US: 'HorstFx',
      de_DE: 'HorstFx',
    },
    type: LicenseType.GROUP,
  }
}
