import { RobotModel } from '@/interfaces'

export const HORST_600: RobotModel = {
  id: '4abe5c2d-45ef-4e96-aed8-5589d71e29e3',
  name: 'HORST 600',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/600.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/600.png`, import.meta.url).href,
  },
  axisMaxRotations: [7_725_000, 6_100_000, 17_076_000, 5_171_000, 8_514_000, 22_503_000],

  selectable: true,
}
