import { ByComponentSerialNumberTypeEnum, ExtendedComponentInfo } from 'hcosmos-api-data'
import { componentApi } from '..'

/**
 *
 * @param serial
 * @param type
 */
export async function findComponent(
  serial: string,
  type: ByComponentSerialNumberTypeEnum,
): Promise<ExtendedComponentInfo> {
  return await componentApi.byComponentSerialNumber({
    serial,
    type,
  })
}
