import { apiBusyState } from '@/data'

const markBusy = <T extends Array<unknown>, U>(
  busyState: keyof typeof apiBusyState | string,
  fn: (...args: T) => Promise<U>,
) => {
  return (...args: T): Promise<U> => {
    apiBusyState[busyState] = true
    try {
      return fn(...args)
    } finally {
      apiBusyState[busyState] = false
    }
  }
}

const onIdle = <T extends Array<unknown>, R>(
  busyState: keyof typeof apiBusyState | string,
  fn: (...args: T) => Promise<R>,
) => {
  return async (...args: T) => {
    if (apiBusyState[busyState] === false) {
      apiBusyState[busyState] = true
      try {
        return fn(...args)
      } finally {
        apiBusyState[busyState] = false
      }
    }
  }
}

export { markBusy, onIdle }
