import { internalApi } from '@/data'
import { HorstFXErrorOptions } from '@/types'
import { Ref } from 'vue'
import { HorstFXError } from 'hcosmos-api-data'

async function loadHfxErrorOptions(horstFXErrorOptionsRef: Ref<HorstFXErrorOptions | null>) {
  if (horstFXErrorOptionsRef.value === null) {
    const respHorstFXError = await internalApi.gethorstFXErrors()
    horstFXErrorOptionsRef.value = mapFullHfxErrorOptions(respHorstFXError)
  }
  return horstFXErrorOptionsRef.value
}

function mapFullHfxErrorOptions(respHorstFXError: HorstFXError[]) {
  return respHorstFXError.map((fxError) => fxError.errorType) as HorstFXErrorOptions
}

export { loadHfxErrorOptions }
